import { Field, useField } from "formik";
import { RFQCard } from "./RFQCard";
import { FlexibleQuoteFile } from "./FlexibleQuoteFile";

const YourQuoteComp = ({
	listQuotes,
	unitMeasures,
	disabled,
	showFlexibleQuoteFile = false,
	submissionFlexibleQuoteFile,
	clientName,
	rfqId,
}) => {
	const [, { value: values }] = useField("quote_answer");

	const tableHeader = [
		{
			name: "#",
			width: "3%",
		},
		{
			name: "Description of Scope",
			width: "40%",
		},
		{
			name: "Unit of Measurement",
			width: "19%",
		},
		{
			name: "Quantity",
			width: "12%",
		},
		{
			name: "Rate",
			width: "12%",
		},
		{
			name: "Cost",
			width: "12%",
		},
	];

	const listResult = [
		{
			id: "subtotal",
			name: "Subtotal",
			color: "#475467",
		},
		{
			id: "gst",
			name: "GST(10%)",
			color: "#475467",
		},
		{
			id: "totalcost",
			name: "Total Cost",
			color: "#101828",
		},
	];
	const formatCurrency = (total) =>
		total.toLocaleString("en-US", { style: "currency", currency: "USD" });

	const totalCost = (type, keys, allValue) => {
		const keyIds = keys === "all" ? Object.keys(allValue || {}) : keys;
		const subTotal = keyIds.reduce(
			(acc, key) => acc + allValue[key].rate * allValue[key].quantity,
			0
		);
		switch (type) {
			case "subtotal":
				return subTotal;
			case "gst":
				return subTotal * 0.1;
			case "totalcost":
				return subTotal + subTotal * 0.1;
			default:
				return "";
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === "-") {
			event.preventDefault();
		}
	};

	return (
		<>
			{listQuotes?.map((quote, index) => {
				return (
					<RFQCard
						title={quote?.name}
						description={quote?.description}
					>
						<table className="w-full table-auto rounded-xl border-b border-solid border-[#EAECF0] bg-white">
							<thead style={{ height: "44px", background: "#F9FAFB" }}>
								<tr className="border-b border-[#EAECF0]">
									{tableHeader.map((th, index) => {
										return (
											<th
												className="font-roboto text-[12px] font-medium text-tertiary-600"
												style={{
													width: th.width,
													textAlign: "left",
													background: "#F9FAFB",
												}}
											>
												<div style={{ marginLeft: "20px", marginTop: "3px" }}>
													{th.name}
												</div>
											</th>
										);
									})}
								</tr>
							</thead>
							<tbody>
								{quote?.items.map((singleData, index2) => {
									return (
										<tr
											key={index2}
											className="border-b border-[#EAECF0]"
										>
											<td
												className="border border-[#EAECF0] px-4 py-2 text-center font-roboto font-normal text-primary-500"
												style={{
													background: "#F9FAFB !important",
													height: "48px",
												}}
											>
												{index2 + 1}
											</td>
											<td
												className="relative border border-[#EAECF0] font-roboto"
												style={{ color: "#333", backgroundColor: "#fafafa" }}
											>
												<Field
													id={singleData.description}
													name={`quote_answer[quote-${singleData.id}][name]`}
												>
													{() => (
														<p className="p-2">{singleData?.name || "-"}</p>
													)}
												</Field>
											</td>
											<td
												className="relative border border-[#EAECF0] font-roboto"
												style={{ color: "#333", backgroundColor: "#fafafa" }}
											>
												<Field
													name={`quote_answer[quote-${singleData.id}][unit]`}
												>
													{({ field }) => (
														<p className="px-2">
															{
																unitMeasures?.find(
																	(unit) => unit.value === field?.value
																)?.label
															}
														</p>
													)}
												</Field>
											</td>
											<td
												className="relative border border-[#EAECF0] px-4 py-2 font-roboto"
												style={{ color: "#333" }}
											>
												<Field
													name={`quote_answer[quote-${singleData.id}][quantity]`}
												>
													{({ field, meta, form }) => (
														<input
															type="number"
															id="quantity"
															{...field}
															value={
																parseFloat(field?.value || 0) > 0
																	? field?.value
																	: null
															}
															onChange={(e) =>
																form.setFieldValue(
																	field.name,
																	parseFloat(e.target.value)
																)
															}
															style={{
																position: "absolute",
																top: 0,
																left: 0,
																width: "100%",
																height: "100%",
																padding: "5px 20px",
																border:
																	meta.touched &&
																	meta.error &&
																	"2px solid #dd0c0cb3",
															}}
															placeholder="0"
															className={`outline-none`}
															min={0}
															onKeyDown={handleKeyDown}
															disabled={disabled}
														/>
													)}
												</Field>
											</td>
											<td
												className="relative border border-[#EAECF0] px-4 py-2 font-roboto"
												style={{ color: "#333" }}
											>
												<div className="flex">
													<div className="absolute left-2 top-1/2 z-10 -translate-y-1/2">
														$
													</div>
													<div>
														<Field
															name={`quote_answer[quote-${singleData.id}][rate]`}
														>
															{({ field, meta, form }) => (
																<div>
																	<input
																		type="number"
																		id="rate"
																		{...field}
																		value={
																			parseFloat(field?.value || 0) > 0
																				? field?.value
																				: null
																		}
																		onChange={(e) =>
																			form.setFieldValue(
																				field.name,
																				e.target.value
																			)
																		}
																		style={{
																			position: "absolute",
																			top: 0,
																			right: 0,
																			width: "100%",
																			height: "100%",
																			padding: "5px 20px",
																			paddingLeft: "20px",
																			border:
																				meta.touched &&
																				meta.error &&
																				"2px solid #dd0c0cb3",
																		}}
																		placeholder="0"
																		className={`outline-none`}
																		min={0}
																		onKeyDown={handleKeyDown}
																		disabled={disabled}
																	/>
																</div>
															)}
														</Field>
													</div>
												</div>
											</td>
											<td
												className="relative border border-[#EAECF0] px-4 py-2 font-roboto"
												style={{ color: "#333" }}
											>
												<Field
													id={singleData.description}
													name={`quote_answer[quote-${singleData.id}][cost]`}
													placeholder=""
													type="text"
													disabled={true}
													style={{
														position: "absolute",
														top: 0,
														left: 0,
														width: "100%",
														height: "100%",
														padding: "5px 20px",
													}}
													value={`${((values?.[`quote-${singleData.id}`]?.quantity || 0) * (values?.[`quote-${singleData.id}`]?.rate || 0)).toLocaleString("en-US", { style: "currency", currency: "USD" })}`}
												/>
											</td>
										</tr>
									);
								})}
								{listResult.map((list) => {
									return (
										<tr>
											<td
												colSpan="4"
												style={{
													background: "#F9FAFB !important",
													height: "48px",
												}}
												className="border-none"
											></td>
											<td
												className="border border-[#EAECF0] bg-gray-utility-100 px-4 py-2"
												style={{ color: "#D0D5DD" }}
											>
												<div
													className="font-roboto font-medium"
													style={{ fontSize: "12px", color: list.color }}
												>
													{list.name}
												</div>
											</td>
											<td
												className="border border-[#EAECF0] bg-gray-utility-100 px-4 py-2"
												style={{ color: "#333" }}
											>
												{formatCurrency(
													totalCost(
														list.id,
														quote?.items.map((item) => `quote-${item.id}`),
														values
													)
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</RFQCard>
				);
			})}
			<div
				className="mb-10 flex items-center justify-start overflow-hidden rounded-xl border border-solid border-[#EAECF0] bg-white shadow-lg"
				style={{ height: "64px" }}
			>
				<div
					className="content-center border-l-4 border-[#003CE9] pl-10 font-roboto text-[18px] font-semibold text-primary-900"
					style={{ height: "100%" }}
				>
					Total
				</div>
				<div
					className="ml-auto content-center border-l border-solid border-[#EAECF0] pl-6 pr-20"
					style={{ height: "100%" }}
				>
					<div
						className="font-roboto font-medium text-primary-900"
						style={{ fontSize: "12px" }}
					>
						Subtotal
					</div>
					<div
						className="font-roboto font-medium"
						style={{ color: "#D0D5DD" }}
					>
						{formatCurrency(totalCost("subtotal", "all", values))}
					</div>
				</div>
				<div
					className="content-center border-l border-solid border-[#EAECF0] pl-6 pr-20"
					style={{ height: "100%" }}
				>
					<div
						className="font-roboto font-medium text-primary-900"
						style={{ fontSize: "12px" }}
					>
						GST (10%)
					</div>
					<div
						className="font-roboto font-medium"
						style={{ color: "#D0D5DD" }}
					>
						{formatCurrency(totalCost("gst", "all", values))}
					</div>
				</div>
				<div
					className="content-center border-solid border-[#EAECF0] bg-royal-blue2 pl-6 pr-20"
					style={{ height: "100%" }}
				>
					<div
						className="font-roboto font-medium text-primary-900"
						style={{ fontSize: "12px", color: "#D0D5DD" }}
					>
						Total Cost
					</div>
					<div className="font-roboto font-medium text-white">
						{formatCurrency(totalCost("totalcost", "all", values))}
					</div>
				</div>
			</div>
			{showFlexibleQuoteFile && (
				<FlexibleQuoteFile
					rfqId={rfqId}
					clientName={clientName}
					flexibleQuoteSavedFile={submissionFlexibleQuoteFile}
					disabled={disabled}
				/>
			)}
		</>
	);
};

export default YourQuoteComp;
