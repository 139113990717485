import React, { useState } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import { showToast } from "utils/helpers";

import { ResourceForm } from "../ResourceForm";
import { editResource, deleteResource } from "../../actions/companyActions";
import { ConfirmDelete } from "../ConfirmDelete";
import Skeleton from "react-loading-skeleton";

export const EditResourceComponent = ({
	resources,
	resourceId,
	editResource,
	deleteResource,
	isRequesting,
	...props
}) => {
	const initialValues = props.initialValues;
	const [autoUpdateForm, setAutoUpdateForm] = useState(false);
	const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

	const handleSubmit = async (values, { setErrors, setStatus }) => {
		try {
			await editResource(resourceId, values, setErrors, setStatus);
			if (autoUpdateForm) {
				navigate("../../resources");
				showToast("Resource updated successfully.", "Success", true);
			}
		} catch (e) {
			console.log("errortype", e);
			if (!(e instanceof Error)) {
				const emailError = e;
				setStatus(emailError.message);
				setErrors(emailError ? { email: emailError.error } : e.message);
				showToast(e.message, "Error");
			} else {
				setStatus("Request Timed out");
				setErrors("Request Timed out");
				showToast("Request Timed out", "Error");
			}
		}
	};

	const handleDelete = async () => {
		try {
			await deleteResource(resourceId);
			navigate("../../resources");
			showToast("Resource deleted successfully.", "Success", true);
		} catch (e) {
			const error = await e.response.json();
			console.log(error);
			showToast(error.message, "Error");
		}
	};

	return (
		<>
			{!isRequesting ? (
				<>
					<ResourceForm
						initialValues={initialValues}
						handleSubmit={handleSubmit}
						handleDelete={() => setConfirmModalIsOpen(true)}
						isRequesting={props.isRequesting}
						setAutoUpdateForm={setAutoUpdateForm}
						autoUpdateForm={autoUpdateForm}
						editForm
					/>

					<ConfirmDelete
						isOpen={confirmModalIsOpen}
						onRequestClose={() => setConfirmModalIsOpen(false)}
						onConfirm={() => handleDelete()}
						onCancel={() => setConfirmModalIsOpen(false)}
						itemName="resource"
						btnText={["Delete", "Deleting..."]}
					/>
				</>
			) : (
				<Skeleton
					count={5}
					duration={0.5}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	//pick out the particular resource from the store
	const resources = state.companyProfile.resources;
	const resource = resources.filter((resource) => {
		return resource.id === parseInt(ownProps.resourceId);
	});

	const values = resource[0] || {};

	console.log("[debug] Edit resource values::: ", values);

	return {
		isRequesting: state.companyProfile.requestingResources,
		initialValues: {
			company_id: state.search.activeCompany.id || "",
			// active: values.active,
			avatar_image: values.avatar_location || "",
			first_name: values.first_name || "",
			last_name: values.last_name || "",
			email: values.email || "",
			position: values.position || "",
			experience: values.experience || "",
			sector_ids: Array.isArray(values.sectors)
				? values.sectors.map((sector) => sector.id)
				: [],
			roles: [1],
			expertise_id: values.expertise?.id || "",
			discipline_ids: Array.isArray(values.disciplines)
				? values.disciplines.map((discipline) => discipline.id)
				: [],
			projects_completed: values.projects_completed,
			resume:
				values.resume && values.resume.length > 0
					? {
							resume_file: {
								path: values.resume ? values.resume[0] : "",
								name: values.resume ? values.resume[1] : "",
							},
						}
					: "",
		},
	};
};

export const EditResource = connect(mapStateToProps, {
	editResource,
	deleteResource,
})(EditResourceComponent);
