import { Heading } from "components/Heading";
import { cn } from "utils/helpers";

type RFQCardProps = {
	title: string;
	description?: string;
	children: React.ReactNode;
	className?: string;
};

export const RFQCard = ({
	title,
	description,
	children,
	className,
}: RFQCardProps) => {
	return (
		<div
			className={cn(
				"mb-10 rounded-lg border border-solid border-[#EAECF0] bg-white p-10 shadow-sm",
				className
			)}
		>
			<Heading className="text-4xl !text-royal-blue">{title}</Heading>
			{description && (
				<p className="mt-3 text-lg text-tertiary-600">{description}</p>
			)}
			<div className="my-9 w-full border-b border-[#D0D5DD] shadow-sm"></div>
			{children}
		</div>
	);
};
