import { Switch as SwitchComponent } from "@chakra-ui/react";
import { cn } from "utils/helpers";

type SwitchProps = {
	id: string;
	className?: string;
	switchClassName?: string;
	isChecked: boolean;
	onChange: () => void;
	label?: string;
	description?: string;
	size: "sm" | "md" | "lg";
};

export const Switch = ({
	id,
	className,
	switchClassName,
	isChecked,
	onChange,
	label,
	description,
	size = "md",
}: SwitchProps) => {
	return (
		<div className={cn("flex flex-col gap-3 sm:flex-row", className)}>
			<SwitchComponent
				id={id}
				className={cn(
					"[&>span:not([data-checked])]:bg-tertiary-100 [&>span[data-checked]]:!bg-royal-blue [&>span[data-focus]]:border-utility-brand-300 [&>span[data-focus]]:ring-4",
					switchClassName
				)}
				isChecked={isChecked}
				onChange={onChange}
				size={size}
			/>

			<div>
				{label && (
					<label
						htmlFor={id}
						className="font-medium text-text-secondary-700"
					>
						{label}
					</label>
				)}
				{description && <p className="text-tertiary-600">{description}</p>}
			</div>
		</div>
	);
};
