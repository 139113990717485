import { Select } from "../Select";
import { MultiSelect } from "../MultiSelect";
import { useQuery } from "@tanstack/react-query";
import { getListExpertiseAndDisciplines } from "./expertiseDisciplinesAPI";
import { useState, useEffect, useMemo } from "react";

export const ExpertiseDisciplinesForForm = ({
	setFieldValue,
	values,
	errors,
	touched,
}) => {
	const [selectedExpertiseId, setSelectedExpertiseId] = useState(
		values.expertise_id || null
	);
	const [selectedDisciplines, setSelectedDisciplines] = useState(
		values.discipline_ids || []
	);

	const { data: expertiseData } = useQuery({
		queryKey: ["expertise-disciplines"],
		queryFn: getListExpertiseAndDisciplines,
	});

	useEffect(() => {
		if (values.expertise_id) {
			setSelectedExpertiseId(values.expertise_id);
		}
		if (values.discipline_ids?.length) {
			setSelectedDisciplines(values.discipline_ids);
			setFieldValue("discipline_ids", values.discipline_ids);
		}
	}, [values.expertise_id, values.discipline_ids, setFieldValue]);

	const expertiseOptions = useMemo(
		() =>
			expertiseData?.data.map((expertise) => ({
				value: expertise.id,
				label: expertise.name,
			})) ?? [],
		[expertiseData]
	);

	const disciplineOptions = useMemo(
		() =>
			selectedExpertiseId
				? (expertiseData?.data
						.find((expertise) => expertise.id === selectedExpertiseId)
						?.disciplines.map((discipline) => ({
							value: discipline.id,
							label: discipline.name,
						})) ?? [])
				: [],
		[selectedExpertiseId, expertiseData]
	);

	const handleExpertiseChange = (option) => {
		setSelectedExpertiseId(option.value);
		setFieldValue("expertise_id", option.value);
		// Reset disciplines when expertise changes
		setSelectedDisciplines([]);
		setFieldValue("discipline_ids", []);
	};

	// Find the selected expertise option
	const selectedExpertiseOption = useMemo(
		() =>
			expertiseOptions.find((option) => option.value === selectedExpertiseId),
		[expertiseOptions, selectedExpertiseId]
	);

	return (
		<div className="grid grid-cols-2 gap-6">
			<Select
				label="Expertise"
				name="expertise_id"
				options={expertiseOptions}
				onChange={handleExpertiseChange}
				placeholder="Select..."
				defaultValue={selectedExpertiseOption}
				noLabel={false}
				value={selectedExpertiseOption}
				className=""
				error={touched.expertise && errors.expertise}
				freeText={false}
				customOnChange={null}
				disabled={false}
				required={true}
				testId="expertise-select"
			/>
			<MultiSelect
				label="Disciplines"
				name="discipline_ids"
				options={disciplineOptions}
				defaultValue={selectedDisciplines
					.map((id) => disciplineOptions.find((opt) => opt.value === id))
					.filter(Boolean)}
				capitalize={false}
				value={selectedDisciplines
					.map((id) => disciplineOptions.find((opt) => opt.value === id))
					.filter(Boolean)}
				onChange={(selectedOptions) => {
					const selectedIds = selectedOptions.map((option) => option.value);
					setSelectedDisciplines(selectedIds);
					setFieldValue("discipline_ids", selectedIds);
				}}
				freeText={false}
				guideLine={false}
				guideLineText=""
				height=""
				width=""
				maxWidth=""
				disabled={!selectedExpertiseId}
				required={true}
				testId="disciplines-multiselect"
			/>
		</div>
	);
};
