import { Flex } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Avatar } from "components/RFQ/Avatar";
import { Button } from "components/RFQ/Button";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/RFQ/Table";
import React from "react";
import tw, { styled, css } from "twin.macro";
import { DEFAULT_IMAGE_AVATAR } from "utils/constants";

const ListViewStyled = styled.div(() => [
	tw`py-8`,
	css`
		.title {
			font-weight: 500;
			font-family: Roboto;
			font-size: 14px;
		}

		.text {
			font-weight: 400;
			font-family: Roboto;
			font-size: 14px;
		}
	`,
]);

const ListViewSupplier = React.memo(
	({
		supplierLists,
		invitedSuppliers,
		handleInviteSupplier,
		handleRemoveSupplier,
	}) => {
		// Get suppliers from supplierLists if it's an array, otherwise use empty array
		const suppliers = Array.isArray(supplierLists) ? supplierLists : [];

		const isInvited = (id) => invitedSuppliers.includes(id);
		const onClickSelectHandler = (id) => {
			if (!isInvited(id)) {
				handleInviteSupplier(id);
			} else {
				handleRemoveSupplier(id);
			}
		};

		return (
			<ListViewStyled>
				<Table containerClass={tw`w-full border rounded-md drop-shadow-xl`}>
					<TableHeader>
						<TableRow>
							<TableHead firstCol={true}>
								<div
									className="flex"
									style={{ gap: "8px", alignItems: "center" }}
								>
									Supplier
								</div>
							</TableHead>
							<TableHead className={tw`max-w-[400px]`}>Name</TableHead>
							<TableHead className={tw`max-w-[218px]`}>Email</TableHead>
							<TableHead className={tw`max-w-[68px]`}>
								<div className="hidden">Icon</div>
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{suppliers.length > 0 ? (
							suppliers.map((supplier, index) => (
								<TableRow key={supplier.id}>
									<TableCell className={tw`min-w-[250px]`}>
										<div
											className="flex gap-3"
											style={{
												alignItems: "center",
												gap: "8px",
											}}
										>
											<Avatar
												avatar={supplier?.logo_path || DEFAULT_IMAGE_AVATAR}
											/>
											<p className="title">{supplier?.name || "-"}</p>
										</div>
									</TableCell>
									<TableCell>
										<p className="text">{supplier?.contact_name || "-"}</p>
									</TableCell>
									<TableCell>
										<p className="text">{supplier?.email || "-"}</p>
									</TableCell>
									<TableCell>
										<Flex
											className="my-3"
											gap={4}
											align={"center"}
											float={"right"}
										>
											<div>
												<Button
													btntype="secondary"
													className="btn-company-profile-wrapper"
													as="a"
													target="_blank"
													href={`/company/${supplier?.id}`}
													style={{ border: "none" }}
												>
													<Flex
														gap={2}
														className="btn-company-profile items-center"
													>
														Company Profile
													</Flex>
												</Button>
											</div>
											<div>
												<Button
													type="button"
													btntype={
														isInvited(supplier.id) ? "primary" : "secondary"
													}
													onClick={() => onClickSelectHandler(supplier?.id)}
													style={{ width: "100%" }}
												>
													{isInvited(supplier.id) ? (
														<Flex
															gap={2}
															className="btn-selected items-center justify-center text-center"
														>
															Selected
															<Icon icon="white-check" />
														</Flex>
													) : (
														<Flex
															gap={2}
															className="btn-selected items-center justify-center text-center"
														>
															Select
														</Flex>
													)}
												</Button>
											</div>
										</Flex>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow className="col-span-5 h-[500px]">
								<div className="absolute left-2/4 -translate-x-2/4">
									<NotFoundDatas
										text={`No supplier found`}
										description={`The supplier list is empty and needs suppliers to create a RFx`}
									/>
								</div>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</ListViewStyled>
		);
	}
);

export default ListViewSupplier;
