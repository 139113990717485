import React, { useState } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";

import { ProjectForm } from "./ProjectForm";
import {
	editPastProject,
	deletePastProject,
} from "../../actions/companyActions";
import { ConfirmDelete } from "../ConfirmDelete";
import { getTradeValues } from "../../actions/companyActions";
import { useEffect } from "react";
import { removeCarriage } from "utils/helpers";
import { showToast } from "utils/helpers";
import { filterImagePathFromFullSignaturePath } from "./ProjectFormHelper";

export const EditProjectComponent = ({
	profileTypeId,
	pastProjects,
	projectId,
	deletePastProject,
	companyId,
	tradeValues,
	...props
}) => {
	const [initialValues, setInitialValues] = useState(null);
	const [autoUpdateForm, setAutoUpdateForm] = useState(false);
	const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
	const [errorStatus, setErrorStatus] = useState(false);
	const [savedField, setSavedField] = useState(props.initialValues);

	useEffect(() => {
		getTradeValues();
	}, []);

	useEffect(() => {
		if (initialValues) {
			const savedImages = savedField?.images?.map((image) =>
				filterImagePathFromFullSignaturePath(image)
			);
			const initialImages = initialValues?.images?.map((image) =>
				filterImagePathFromFullSignaturePath(image)
			);
			if (JSON.stringify(savedImages) !== JSON.stringify(initialImages)) {
				setInitialValues(savedField);
			}
			if (
				JSON.stringify(
					filterImagePathFromFullSignaturePath(savedField?.testimonials)
				) !==
				JSON.stringify(
					filterImagePathFromFullSignaturePath(initialValues?.testimonials)
				)
			) {
				setInitialValues(savedField);
			}
			if (
				JSON.stringify(savedField?.trade_ids) !==
				JSON.stringify(initialValues?.trade_ids)
			) {
				setInitialValues(savedField);
			}
		}
	}, [savedField, initialValues]);

	useEffect(() => {
		if (!initialValues) {
			setInitialValues(props.initialValues);
			setSavedField(props.initialValues);
		}
	}, [props.initialValues, initialValues]);

	const handleSubmit = async (values, { setErrors, setStatus }) => {
		try {
			await props.editPastProject(
				projectId,
				values,
				autoUpdateForm,
				setSavedField,
				companyId,
				props.tradeValues
			);

			if (autoUpdateForm) {
				setAutoUpdateForm(false);
				// need to check to avoid navigation to 404 due to auto save
				if (window?.location.href.includes("/projects/edit-project")) {
					navigate("../../projects");
				}
			}
		} catch (error) {
			showToast("Sorry, an error has occurred.", "Error");
			setErrorStatus(true);
			setStatus("Sorry, an error has occurred.");
		}
	};

	const handleDelete = async () => {
		try {
			await deletePastProject(projectId);
			navigate("../../projects");
			showToast("Project deleted successfully.", "Success", true);
		} catch (e) {
			showToast("Sorry, an error has occurred.", "Error");
		}
	};

	return (
		<>
			{initialValues && (
				<ProjectForm
					initialValues={initialValues}
					handleSubmit={handleSubmit}
					handleDelete={() => setConfirmModalIsOpen(true)}
					isRequesting={props.isRequesting}
					profileTypeId={profileTypeId}
					setAutoUpdateForm={setAutoUpdateForm}
					errorStatus={errorStatus}
					setErrorStatus={setErrorStatus}
					autoUpdateForm={autoUpdateForm}
					editForm={true}
					savedField={savedField}
					setInitialValue={setInitialValues}
					setSavedField={setSavedField}
				/>
			)}

			<ConfirmDelete
				isOpen={confirmModalIsOpen}
				onRequestClose={() => setConfirmModalIsOpen(false)}
				onConfirm={() => handleDelete()}
				onCancel={() => setConfirmModalIsOpen(false)}
				itemName="project"
				btnText={["Delete", "Deleting..."]}
			/>
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	const id = ownProps.projectId;
	const { pastProjects, tradeValues } = state.companyProfile;

	const values =
		pastProjects.find((project) => parseInt(project.id) === parseInt(id)) || {};
	const checkTrade = () => {
		const optionIndex = tradeValues.findIndex(
			(option) => values.trade_value === option.name
		);
		return optionIndex === -1 ? 1 : tradeValues[optionIndex].id;
	};
	return {
		companyId: state.search.activeCompany.id,
		pastProjects: pastProjects,
		tradeValues: tradeValues,
		isRequesting: state.companyProfile.isRequesting,
		initialValues:
			pastProjects.length > 0
				? {
						name: values.name || "",
						location: values.location || "",
						summary: removeCarriage(values.summary) || "",
						value: values.value?.toString() || "0",
						trade_value: checkTrade() || 1,
						year_completed: values.year_completed?.toString() || "",
						funding_type: values.funding_type || "",
						site_area: values.site_area || "",
						gross_floor_area: values.gross_floor_area || "",
						scope: values.scope || [],
						client_name: values.client_name || "",
						head_contractor: values.head_contractor || "",
						sector_id: values.sector ? values.sector.id : "",
						images: values.images || [],
						innovation_value_summary:
							removeCarriage(values.innovation_value_summary) || "",
						awards: removeCarriage(values.awards) || "",
						testimonials: values.testimonials || "",
						status: values.status,
						expertise_id: values.expertise?.id || "",
						discipline_ids: Array.isArray(values.disciplines)
							? values.disciplines.map((discipline) => discipline.id)
							: [],
						procurement_method_ids: Array.isArray(values.procurement_methods)
							? values.procurement_methods.map((method) => method.id)
							: [],
						trade_ids: Array.isArray(values.trades)
							? values.trades.map((trade) => trade.id)
							: [],
					}
				: null,
	};
};

export const EditProject = connect(mapStateToProps, {
	editPastProject,
	deletePastProject,
})(EditProjectComponent);
