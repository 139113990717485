import { FileUpload } from "components/RFQ/FileUpload";
import { RFQCard } from "./RFQCard";

export const FlexibleQuoteFile = ({
	rfqId,
	clientName,
	disabled = false,
	flexibleQuoteSavedFile,
}) => {
	const fileData = flexibleQuoteSavedFile
		? {
				name: flexibleQuoteSavedFile.file_name,
				path: flexibleQuoteSavedFile.file_path,
				file_size: flexibleQuoteSavedFile.file_size,
				type: flexibleQuoteSavedFile.file_type,
				downloadUrl: flexibleQuoteSavedFile.file_path,
			}
		: null;

	return (
		<>
			<RFQCard
				title="Alternative Quote"
				description={`${clientName} has made Flexible Quoting available for this RFx. If you have an alternative quote, upload it here. (optional)`}
			>
				{disabled && !fileData ? (
					<div className="my-4 text-gray-500">
						No alternative quote submitted.
					</div>
				) : (
					<FileUpload
						type="file"
						name="flexible_quote_file"
						className="my-4 w-full"
						types={["xlsx", "xls", "csv"]}
						multiple={false}
						maxFileSize={100}
						disabled={disabled}
						isPreview={disabled}
						fileData={fileData}
						fileExtraData={{
							model: "flexible_quote_file",
							rfq_id: rfqId,
						}}
					/>
				)}
			</RFQCard>
		</>
	);
};
