import apiV2, { apiV1 } from "api-v2";
import { toFormData } from "axios";
import { showToast, processFormData } from "utils/helpers";

export const getSubmission = async (rfqId, companyId) => {
	try {
		let response = await apiV2.get(
			`rfq/${rfqId}/submission?companyId=${companyId}`
		);
		if (response.status === 200) {
			let quoteData = response?.data?.data?.submission?.quote;
			let returnableData = response?.data?.data?.submission?.returnableSchedule;
			let submissionFlexibleQuoteFile =
				response?.data?.data?.submission?.flexible_quote_file;
			let submissionQuoteFile = response?.data?.data?.submission?.quote_file;
			let returnableScheduledSavedFile =
				response?.data?.data?.submission?.returnable_schedule_file;

			return {
				submissionStatus: response?.data?.data?.status,
				quoteData: quoteData,
				returnableData: returnableData,
				rfqDetail: response?.data?.data?.rfq,
				submissionQuoteFile,
				submissionFlexibleQuoteFile,
				returnableScheduledSavedFile,
			};
		} else {
			return null;
		}
	} catch {
		return null;
	}
};

export const acceptDecliceSubmission = async (rfqId, isAccept, reason) => {
	const data = toFormData({
		isAccept: isAccept,
		reason: reason,
	});
	try {
		let response = await apiV2.post(`/rfq/${rfqId}/accept-or-decline`, data);
		if (response.status === 200) {
			showToast(response.data.message, "Success", true);
			return response.data;
		} else {
			showToast(response.data.message, "Error");
			return false;
		}
	} catch (e) {
		console.log(e);
		showToast(e?.response?.data?.message, "Error");
		return false;
	}
};

export const saveSubmissionRFQ = async (rfqId, value) => {
	const formData = toFormData(value);
	try {
		let response = await apiV2.post(`rfq/${rfqId}/store-submission`, formData);
		if (response.status === 200) {
			showToast(response.data.message, "Success", true);
			return response.data.data;
		} else {
			showToast("Failed to save submission", "Error");
			return false;
		}
	} catch (e) {
		console.log(e);
		showToast("Failed to save submission", "Error");
	}
};

export const submitSubmissionRFQ = async (rfqId, companyId, isRead) => {
	const formData = toFormData({ companyId, isRead });
	try {
		let response = await apiV2.post(`rfq/${rfqId}/submit-submission`, formData);
		if (response.status === 200) {
			showToast(response.data.message, "Success", true);
			return response.data.data;
		} else {
			showToast(response.data.message, "Error");
			return false;
		}
	} catch (e) {
		console.log(e);
		showToast(
			e?.response?.data?.message || "Failed to submit submission",
			"Error"
		);
	}
};

export const downloadAllDocumentation = async (rfqId) => {
	try {
		let response = await apiV2.get(`rfq/${rfqId}/download-documents`, {
			responseType: "blob",
		});
		if (response.status === 200) {
			const url = window.URL.createObjectURL(response.data);
			const a = document.createElement("a");
			a.href = url;
			a.download = "download.zip";
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			showToast("Documents downloaded", "Success", true);
			return true;
		} else {
			showToast("Failed to download documents", "Error");
			return false;
		}
	} catch (e) {
		showToast("Failed to download documents", "Error");
		return false;
	}
};

export const getListCompanyData = async (type, params) => {
	try {
		let response = await apiV2.get(`${type}`, { params });
		if (response.status === 200) {
			return response.data.data;
		}
	} catch (e) {
		console.log(e);
		showToast(e?.response?.data?.message, "Error");
	}
};
export const getListProjectExperience = async ({ queryKey }) => {
	// eslint-disable-next-line no-unused-vars
	const [_, params] = queryKey;
	try {
		const response = await apiV2.get(`project-experience`, { params });
		if (response.status === 200) {
			return response.data.data;
		}
		throw new Error("Failed to fetch project experience");
	} catch (err) {
		throw new Error(
			err?.response?.data?.message || "Failed to fetch project experience"
		);
	}
};

export const getListKeyPersonnels = async ({ queryKey }) => {
	// eslint-disable-next-line no-unused-vars
	const [_, params] = queryKey;
	try {
		const response = await apiV2.get(`key-personnel`, { params });
		if (response.status === 200) {
			return response.data.data;
		}
		throw new Error("Failed to fetch key personnel");
	} catch (err) {
		throw new Error(
			err?.response?.data?.message || "Failed to fetch key personnel"
		);
	}
};

export const getPastProjectForRfx = async ({ queryKey }) => {
	// eslint-disable-next-line no-unused-vars
	const [_, { projectId, rfqId }] = queryKey;

	try {
		const response = await apiV1.get(
			`/past-projects/${projectId}?replacement=1&rfq_id=${rfqId}`
		);

		if (response.status === 200) {
			return response.data.data;
		}

		throw new Error("Failed to fetch past project for RFX");
	} catch (err) {
		throw new Error(
			err?.response?.data?.message || "Failed to fetch past project for RFX"
		);
	}
};

export const getPastKeyPersonnelForRfx = async ({ queryKey }) => {
	// eslint-disable-next-line no-unused-vars
	const [_, { keyPersonnelId, rfqId }] = queryKey;

	try {
		const response = await apiV1.get(
			`/companies/key-personnel/${keyPersonnelId}?replacement=1&rfq_id=${rfqId}`
		);

		if (response.status === 200) {
			return response.data.data.key_personnel;
		}
		throw new Error("Failed to fetch key personnel for RFX");
	} catch (err) {
		throw new Error(
			err?.response?.data?.message || "Failed to fetch key personnel for RFX"
		);
	}
};

export const saveProjectChangesForRfx = async (projectId, rfxId, values) => {
	try {
		// create a copy of values to avoid modifying the original
		const formValues = { ...values };

		// Add rfxId to the values
		formValues.rfq_id = rfxId;

		// Handle testimonials field - set to null if empty string
		if (formValues.testimonials === "") {
			formValues.testimonials = null;
		}

		// Use the processFormData helper to properly format the form data
		const formData = processFormData(formValues);

		const response = await apiV2.post(
			`/project-experience/${projectId}/save-replacement`,
			formData
		);

		if (response.status === 200) {
			const message =
				response.data?.message ||
				"Project changes saved successfully for this RFX";

			showToast(message, "Success", true);

			return {
				status: true,
				response: {
					message: message,
				},
				data: response.data,
			};
		}
	} catch (err) {
		showToast(
			err?.response?.data?.message || "Failed to save project changes",
			"error"
		);
		throw err;
	}
};

export const saveKeyPersonnelChangesForRfx = async (
	keyPersonnelId,
	rfxId,
	values
) => {
	try {
		const formValues = { ...values };

		// Add rfqId to the values
		formValues.rfq_id = rfxId;

		// We don't need to add roles if it's not in the form
		// Roles handling can be removed since it's not required by the API

		// Handle resume_file field
		if (formValues.resume_file === "" || formValues.resume_file === undefined) {
			formValues.resume_file = null;
		}

		// Handle resume field
		if (formValues.resume === "" || formValues.resume === undefined) {
			formValues.resume = null;
		}

		// Use the processFormData helper to properly format the form data
		const formData = processFormData(formValues);

		const response = await apiV2.post(
			`/key-personnel/${keyPersonnelId}/save-replacement`,
			formData
		);

		if (response.status === 200) {
			showToast(
				response.data.message || "Key personnel saved successfully",
				"Success",
				true
			);
			return response.data;
		}

		throw new Error("Failed to save key personnel changes");
	} catch (err) {
		showToast(
			err?.response?.data?.message || "Failed to save key personnel changes",
			"Error"
		);

		// Return the error for proper handling in the component
		if (err.response && err.response.data) {
			throw err.response.data;
		}
		throw err;
	}
};

export const getListCorporateDocument = async ({ queryKey }) => {
	// eslint-disable-next-line no-unused-vars
	const [_, params] = queryKey;
	try {
		const response = await apiV2.get(`corporate-document`, { params });
		if (response.status === 200) {
			return response.data.data;
		}
	} catch (err) {
		throw new Error(
			err?.response?.data?.message || "Failed to fetch corporate documents"
		);
	}
};
