import { useState } from "react";
import { Button } from "components/RFQ/Button";
import { Icon } from "assets/icons/Icon";
import { cn } from "utils/helpers";

export const CompanySuggestionContent = ({
	companyData,
	onClose,
	className,
	onAddToList,
	isLoading = false,
}) => {
	const [selectedCompanies, setSelectedCompanies] = useState([]);

	const handleCompanySelect = (companyId) => {
		setSelectedCompanies((prev) =>
			prev.includes(companyId)
				? prev.filter((id) => id !== companyId)
				: [...prev, companyId]
		);
	};

	const handleAddToSupplierList = () => {
		if (selectedCompanies.length > 0) {
			const selectedCompany = companyData?.data?.find((c) =>
				selectedCompanies.includes(c.id)
			);
			onAddToList?.(selectedCompanies, selectedCompany);
		}
	};

	return (
		<div className={cn("suggestion-content w-full space-y-5", className)}>
			<div className="space-y-2">
				<p className="font-medium text-utility-brand-700">
					Current a Procuracon user
				</p>
				<p className="text-[14px] text-tertiary-600">
					The person you would like to invite as guest is a Procuracon user
					already. Would you like to add them to the supplier list above?
				</p>
			</div>
			{!companyData || !companyData.data ? (
				<div className="space-y-3">
					{[1, 2].map((i) => (
						<div
							key={i}
							className="h-4 w-1/2 animate-pulse rounded bg-gray-200"
						></div>
					))}
				</div>
			) : (
				<>
					<div className="flex flex-wrap items-end justify-between gap-y-4">
						<div className="flex flex-wrap gap-3">
							{companyData?.data?.map((company) => (
								<button
									key={company.id}
									onClick={() => handleCompanySelect(company.id)}
									className={cn(
										"rounded-[8px] border px-3 py-2 transition-all",
										selectedCompanies.includes(company.id)
											? "border-royal-blue ring-4 ring-tertiary-400/20 ring-offset-0"
											: "border-tertiary-300"
									)}
								>
									<div
										className={cn(
											"flex items-center gap-1 text-[14px]",
											selectedCompanies.includes(company.id)
												? "text-royal-blue [&_svg]:text-royal-blue"
												: "text-tertiary-600 [&_svg]:text-tertiary-600"
										)}
									>
										<Icon icon="building-07" />
										<p>
											{company.name}{" "}
											{companyData?.data?.length > 1 &&
												company.locations?.length > 0 &&
												company.locations[0]?.state}
										</p>
									</div>
								</button>
							))}
						</div>
						<div className="flex shrink-0 grow-0 justify-end gap-4">
							<Button
								btntype="base"
								size="sm"
								onClick={onClose}
								disabled={isLoading}
							>
								Close
							</Button>
							<Button
								btntype="primary"
								size="sm"
								onClick={handleAddToSupplierList}
								disabled={selectedCompanies.length === 0 || isLoading}
								isLoading={isLoading}
							>
								Add to list
							</Button>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
