import { removePrefixFromKeys } from "utils/helpers";
import { isArray, isFile, isSmartField } from "utils/validation";

export const initialQuoteValue = (quoteData) => {
	let quoteValues = {};
	quoteData.forEach((quote) => {
		quote.items.forEach((item) => {
			const { rqfQuoteAnswer } = item;
			quoteValues[`quote-${item.id}`] = {
				quantity: rqfQuoteAnswer?.answer?.value?.quantity || 0,
				rate: rqfQuoteAnswer?.answer?.value?.rate || 0,
				unit: item.unit?.id || null,
			};
		});
	});
	return { quote_answer: { ...quoteValues } };
};

export const initialReturnableValue = (
	returnableList,
	isGuest,
	hasDocuments,
	hasPasProjects,
	hasResources
) => {
	let questionValues = {};

	returnableList?.forEach((section) => {
		section.question?.forEach((question) => {
			if (isSmartField(question?.fields?.type)) {
				if (isGuest) {
					if (question?.fields?.type === "corporate_documents") {
						questionValues[`question-${question.id}`] = question?.rfqAnswer
							?.answer?.value
							? question?.rfqAnswer?.answer?.value?.map((file) => ({
									file_name: file?.fileName || " - ",
									file_size: file?.fileSize || null,
									file_path: file?.filePath || null,
								}))
							: [];
					} else {
						questionValues[`question-${question.id}`] =
							question?.rfqAnswer?.answer?.value[0] || [];
					}
				} else {
					/**
					 * if supplier but no documents show upload field
					 */
					if (question?.fields?.type === "corporate_documents") {
						const value = question?.rfqAnswer?.answer?.value;
						const chosen_type = question?.rfqAnswer?.answer?.chosen_type;

						if (chosen_type === "select") {
							// For selected documents - extract only id
							questionValues[`question-${question.id}`] = value
								? value.map((item) => item.id)
								: [];
						} else {
							questionValues[`question-${question.id}`] = value
								? value.map((file) => ({
										file_name: file?.fileName || " - ",
										file_size: file?.fileSize || null,
										file_path: file?.filePath || null,
									}))
								: [];
						}
					} else if (
						(question?.fields?.type === "project_experience" &&
							!hasPasProjects) ||
						(question?.fields?.type === "key_personnel" && !hasResources)
					) {
						/**
						 *
						 * if supplier but no projects or resources show textarea
						 */
						let additional = question?.rfqAnswer?.answer?.value || [];
						questionValues[`question-${question.id}`] = {
							chosen_type: "other",
							additional: additional,
						};
					} else {
						questionValues[`question-${question.id}`] = question?.rfqAnswer
							?.answer?.value
							? typeof question?.rfqAnswer?.answer?.value === "string"
								? []
								: question?.rfqAnswer?.answer?.value
										.map((ans) => ans?.id)
										.filter((ans) => ans !== undefined)
							: [];
					}
				}
			} else if (isFile(question?.fields?.type)) {
				questionValues[`question-${question.id}`] = question?.rfqAnswer
					? question?.rfqAnswer?.answer?.value?.map((file) => ({
							file_name: file?.fileName || " - ",
							file_size: file?.fileSize || null,
							file_path: file?.filePath || null,
						}))
					: [];
			} else if (isArray(question?.fields?.type)) {
				questionValues[`question-${question.id}`] = question?.rfqAnswer?.answer
					?.value
					? question?.rfqAnswer?.answer?.value.map((ans) => ans.toString())
					: [];
			} else if (question?.fields?.type === "table") {
				if (
					question?.rfqAnswer?.answer?.value &&
					Array.isArray(question?.rfqAnswer?.answer?.value)
				) {
					question?.rfqAnswer?.answer?.value?.map((ans, index) => {
						return (questionValues[`question-${question.id}-${[index]}`] =
							ans?.toString());
					});
				} else {
					question?.fields?.table[1]?.values?.map((value, index) => {
						return (questionValues[`question-${question.id}-${[index]}`] = value
							? value.toString()
							: "");
					});
				}
			} else {
				questionValues[`question-${question.id}`] =
					question?.rfqAnswer?.answer?.value?.toString()
						? question?.rfqAnswer?.answer?.value.toString()
						: null;
			}
		});
	});
	return {
		question_answer: { ...questionValues },
	};
};

export const valuesPayload = (values, returnableList, isGuest) => {
	let returnablePayload = removePrefixFromKeys(
		{ ...values?.question_answer },
		"question-"
	);
	let quotePayload = removePrefixFromKeys(
		{ ...values?.quote_answer },
		"quote-"
	);
	const quoteFile = values?.quote_file;
	const returnableScheduleFile = values?.returnable_schedule_file;

	returnableList?.forEach((section) => {
		section.question?.forEach((question) => {
			const value = returnablePayload[question.id];
			if (question?.fields?.type === "corporate_documents") {
				// when file is uploaded (new file)

				if (Array.isArray(value) && value[0]?.file_size) {
					returnablePayload[question.id] = {
						chosen_type: "other",
					};
				}
				// when existing document is selected
				if (Array.isArray(value) && !value[0]?.file_size) {
					// Type SAFE --Filter only number values from the array
					const selectedIds = value.filter(
						(item) =>
							typeof item === "number" ||
							(typeof item === "string" && !isNaN(item))
					);
					returnablePayload[question.id] = selectedIds;
				}

				return;
			}

			// keep original logic
			if (
				value === "" &&
				(question?.fields?.type === "text" ||
					question?.fields?.type === "textarea")
			) {
				returnablePayload[question.id] = "<p></p>";
			}
			if (Array.isArray(value)) {
				value.forEach((val, i) => {
					if (
						typeof val === "object" &&
						!Array.isArray(val) &&
						val !== null &&
						!(val instanceof File)
					) {
						returnablePayload[question.id][i] = val?.file_name;
					}
				});
			}
			if (Array.isArray(value) && value?.length === 0) {
				returnablePayload[question.id] = { isRemove: 1 };
			}
			if (question?.fields?.type === "table") {
				let answers = [];

				const filteredObject = Object.keys(values?.question_answer).reduce(
					(acc, key) => {
						if (key.startsWith(`question-${question?.id}`)) {
							acc[key] = values?.question_answer[key];
						}
						return acc;
					},
					{}
				);

				Object.keys(filteredObject).forEach((key) => {
					answers.push(filteredObject[key]);
				});

				returnablePayload[question.id] = answers;
			}
			if (
				isGuest &&
				isSmartField(question?.fields?.type) &&
				question?.fields?.type !== "corporate_documents"
			) {
				returnablePayload[question.id] = [value];
			}
		});
	});

	for (const quote in quotePayload) {
		const quoteValue = quotePayload[quote];
		quotePayload[quote].rate = quoteValue.rate || 0;
		quotePayload[quote].quantity = quoteValue.quantity || 0;
		quotePayload[quote].unit = quoteValue.unit || 1;
	}

	const quoteResponse = quoteFile
		? { quote_file: quoteFile[0] }
		: { quote_answer: quotePayload };
	const returnableResponse = returnableScheduleFile
		? { returnable_schedule_file: returnableScheduleFile[0] }
		: { question_answer: returnablePayload };

	return {
		...returnableResponse,
		...quoteResponse,
	};
};

export const touchFieldReturnable = (returnableList) => {
	const returnableSchedules = {};
	returnableList?.forEach((section) => {
		section.question?.forEach((question) => {
			returnableSchedules[`question-${question.id}`] = true;
		});
	});
	return returnableSchedules;
};
