import React from "react";
import { FileCard } from "components/RFQ/FileCard";
import { FileUpload } from "components/RFQ/FileUpload";
import LabelForm from "components/RFQ/LabelForm/LabelForm";
import { FlexibleQuoteFile } from "./FlexibleQuoteFile";

export default function QuoteFile({
	quoteFilepath,
	companyName,
	rfqId,
	disabled,
	submissionQuoteFile,
	submissionFlexibleQuoteFile,
	clientName,
	showFlexibleQuoteFile = false,
	quote,
}) {
	const fileData = {
		name: "Quote template.xlsx",
		path: quoteFilepath,
		description: `${companyName} has submitted a quote template. Please download it and re-upload below once it has been completed`,
	};

	const previewQuoteFileData = submissionQuoteFile
		? {
				name: submissionQuoteFile.file_name,
				path: submissionQuoteFile.file_path,
				file_size: submissionQuoteFile.file_size,
				type: submissionQuoteFile.file_type,
				downloadUrl: submissionQuoteFile.file_path,
			}
		: null;

	return (
		<>
			<LabelForm label="Download quotation template" />
			<FileCard
				file={fileData}
				isPreview
			/>

			<FileUpload
				type="file"
				label="Attach quote response"
				name="quote_file"
				className="my-4 w-full"
				types={["xlsx", "xls", "csv"]}
				required
				multiple={false}
				maxFileSize={100}
				disabled={disabled}
				isPreview={disabled}
				fileData={previewQuoteFileData}
				fileExtraData={{
					model: "rfq_quote_file_response",
					rfq_id: rfqId,
				}}
				testId="quote-file-upload"
			/>
			{showFlexibleQuoteFile && (
				<FlexibleQuoteFile
					rfqId={rfqId}
					clientName={clientName}
					flexibleQuoteSavedFile={submissionFlexibleQuoteFile}
					disabled={disabled}
				/>
			)}
		</>
	);
}
