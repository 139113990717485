import React, {
	useEffect,
	useState,
	useRef,
	useContext,
	useCallback,
} from "react";
import { cn, getFileNameFromUrl, showToast } from "utils/helpers";

import Dropzone from "react-dropzone";
import { Tooltip } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { FileCard } from "../FileCard";
import { useField } from "formik";
import LabelForm from "../LabelForm/LabelForm";
import { RFQContext } from "context/RFQContext";
import {
	addProgressToUploading,
	removeProgressFromUploading,
} from "utils/rfxLargeFileUploadHelper";

const typeChecker = (types) => {
	const fileTypesObj = {};
	for (const type of types) {
		switch (type) {
			case "jpg":
				fileTypesObj["image/jpeg"] = [".jpg", ".jpeg"];
				break;
			case "png":
				fileTypesObj["image/png"] = [".png"];
				break;
			case "gif":
				fileTypesObj["image/gif"] = [".gif"];
				break;
			case "svg":
				fileTypesObj["image/svg+xml"] = [".svg"];
				break;
			case "pdf":
				fileTypesObj["application/pdf"] = [".pdf"];
				break;
			case "doc":
				fileTypesObj["application/msword"] = [".doc"];
				break;
			case "docx":
				fileTypesObj[
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				] = [".docx"];
				break;
			case "xls":
				fileTypesObj["application/vnd.ms-excel"] = [".xls"];
				break;
			case "xlsx":
				fileTypesObj[
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				] = [".xlsx"];
				break;
			case "csv":
				fileTypesObj["text/csv"] = [".csv"];
				break;
			case "ppt":
				fileTypesObj["application/vnd.ms-powerpoint"] = [".ppt"];
				break;
			case "pptx":
				fileTypesObj[
					"application/vnd.openxmlformats-officedocument.presentationml.presentation"
				] = [".pptx"];
				break;
			case "zip":
				fileTypesObj["application/zip"] = [".zip"];
				break;
			default:
				break;
		}
	}
	return fileTypesObj;
};

const regexType = /\.([a-zA-Z0-9]+)(?=\?|$)/;

export const FileUpload = ({
	label,
	multiple = false,
	children,
	className,
	hint,
	description,
	required,
	types = [],
	maxFileSize,
	defaultFileName,
	disabled = false,
	fileExtraData,
	handleFileUploadComplete,
	isProcurerRFx,
	fileData,
	...props
}) => {
	const [field, meta, helpers] = useField(props);
	const [files, setFiles] = useState(multiple ? [] : null);
	const inputRef = useRef(null);
	const { value } = field;
	const { setUploadInProgresses } = useContext(RFQContext);

	useEffect(() => {
		if ((files?.length === 0 || files === null) && meta?.value) {
			setFiles(meta.value);
		}
		//eslint-disable-next-line
	}, [meta]);

	useEffect(() => {
		if (files !== null && !multiple) {
			requestAnimationFrame(() => {
				if (props?.singleFile) {
					helpers.setValue(files);
				} else {
					helpers.setValue(files);
				}
			});
		}
	}, [files, helpers, multiple, props?.singleFile]);

	const dropHandler = useCallback(
		(acceptedFiles, rejectedFiles) => {
			if (rejectedFiles.length > 0) {
				rejectedFiles.forEach((file) => {
					file?.errors?.length > 0 &&
						file.errors[0].code === "file-invalid-type" &&
						showToast("Invalid file type", "Error");
				});
				return;
			}

			if (multiple) {
				acceptedFiles.forEach((file) => {
					if (file.size > maxFileSize * 1024 * 1024) {
						showToast(`Maximum file size is ${maxFileSize}MB.`, "Error");
					} else {
						setTimeout(() => {
							helpers.setValue([...files, file]);
							setFiles((prev) => [...prev, file]);
						}, 0);
					}
				});
			} else {
				if (rejectedFiles.length > 1) {
					showToast("Please upload a single file", "Error");
				} else if (acceptedFiles[0].size > maxFileSize * 1024 * 1024) {
					showToast(`Maximum file size is ${maxFileSize}MB.`, "Error");
				} else {
					setTimeout(() => {
						props?.singleFile
							? setFiles(acceptedFiles[0])
							: setFiles(acceptedFiles);
					}, 0);
				}
			}
		},
		[multiple, maxFileSize, props?.singleFile, setFiles, files, helpers]
	);

	const url = (value) =>
		typeof value === "string" ? getFileNameFromUrl(value) || "" : "";
	const fileExt = (url) =>
		url && typeof url === "string" ? url?.match(regexType) : null;

	const extension = fileExt(url(value)) ? fileExt(url(value))[0] : null;

	const deleteHandler = (index) => {
		const newFiles = files.filter((file, i) => i !== index);
		helpers.setValue(newFiles);
		setFiles(newFiles);
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			if (inputRef.current) {
				inputRef?.current?.click();
			}
		}
	};

	const getFileObject = (uploadedFile) => {
		if (uploadedFile) {
			return Object.keys(uploadedFile).includes("question-id")
				? {
						file_name: uploadedFile?.file?.fileName,
						file_path: uploadedFile?.file?.filePath,
						file_size: uploadedFile?.file?.file_size,
					}
				: uploadedFile;
		}
	};

	const onFileUploadComplete = useCallback(
		(uploadedFile) => {
			setTimeout(() => {
				setFiles((prev) => {
					if (Array.isArray(prev)) {
						const updatedFiles = prev?.map((file) => {
							const fileName = file?.name?.trim().replace(/\s+/g, "_");
							if (
								file instanceof File &&
								(fileName === uploadedFile?.file?.fileName ||
									fileName === uploadedFile?.file_name)
							) {
								return getFileObject(uploadedFile);
							}
							return file;
						});

						helpers.setValue(updatedFiles);
						return updatedFiles;
					}

					helpers.setValue(uploadedFile);
					return uploadedFile?.file;
				});

				helpers.setError();
				handleFileUploadComplete(uploadedFile);

				if (fileExtraData) {
					setUploadInProgresses((prev) =>
						removeProgressFromUploading(prev, isProcurerRFx)
					);
				}
			}, 0);
		},
		[
			helpers,
			handleFileUploadComplete,
			fileExtraData,
			isProcurerRFx,
			setUploadInProgresses,
		]
	);

	const handleFileUploadStart = () => {
		if (fileExtraData) {
			setUploadInProgresses((prev) =>
				addProgressToUploading(prev, isProcurerRFx)
			);
		}
	};
	const handleFileUploadError = () => {
		if (fileExtraData) {
			setUploadInProgresses((prev) =>
				removeProgressFromUploading(prev, isProcurerRFx)
			);
		}
	};

	return (
		<div className={cn("w-full", className)}>
			{label && (
				<>
					<LabelForm
						label={label}
						required={required}
					></LabelForm>
					{hint && (
						<Tooltip
							dataTip={`input-${props.name}`}
							content={hint}
						>
							<Icon
								icon="info-circle"
								className="text-gray-500"
								size="sm"
							/>
						</Tooltip>
					)}
				</>
			)}
			{description && (
				<p className="mb-2 whitespace-pre-wrap text-sm font-normal text-gray-text-400">
					{description}
				</p>
			)}
			{!disabled && (
				<div className="cursor-pointer">
					<Dropzone
						onDrop={(acceptedFiles, rejectedFiles) =>
							dropHandler(acceptedFiles, rejectedFiles)
						}
						accept={typeChecker(types)}
						multiple={multiple}
						disabled={disabled}
					>
						{({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
							<section>
								<div
									{...getRootProps()}
									className={cn(
										"relative mx-auto h-max w-full content-center rounded-2xl border border-dashed bg-white py-10 text-center",
										`border-solid ${isDragReject && "border-utility-error-500"} ${isDragAccept && "border-utility-brand-700"} ${disabled && "pointer-events-none bg-gray-300 bg-opacity-25"}`,
										meta.error && meta.touched && "border-red-400",
										props?.inputBoxClassName
									)}
								>
									<div ref={inputRef}>
										<input
											{...getInputProps()}
											data-testid={`${props?.testId}`}
										/>
									</div>
									<Icon
										icon="upload"
										className="icon-download"
										style={{
											position: "relative",
											marginLeft: "auto",
											marginRight: "auto",
										}}
									/>
									{children}
									<p className="text-sm">
										<span
											className="focusable font-semibold text-royal-blue"
											onKeyDown={handleKeyDown}
										>
											Click to upload
										</span>{" "}
										or drag and drop
									</p>
									<p className="text-sm">
										<span className="uppercase">
											{types.length > 0 && types.join(", ")}
										</span>
										{maxFileSize && ` (Max file size ${maxFileSize}MB)`}
									</p>
								</div>
							</section>
						)}
					</Dropzone>
				</div>
			)}
			<div className="bg-white">
				{Array.isArray(files) ? (
					<>
						{files &&
							files.length > 0 &&
							files.map((file, i) => (
								<FileCard
									key={`file-${i}-${file.name}`}
									file={
										typeof file === "string"
											? { name: getFileNameFromUrl(file) }
											: file
									}
									fileExtraData={fileExtraData}
									setFiles={() => deleteHandler(i)}
									handleFileUploadComplete={onFileUploadComplete}
									handleFileUploadError={handleFileUploadError}
									handleFileUploadStart={handleFileUploadStart}
									isPreview={disabled}
									isDraggable={false}
								/>
							))}
					</>
				) : files ? (
					typeof value === "string" && fileExt(url(value)) && !multiple ? (
						<>
							{
								<FileCard
									file={{
										name: `${defaultFileName ? defaultFileName + extension : url(value)}`,
										path: fileData?.path,
										downloadUrl: value,
										file_size: fileData?.file_size,
										type: fileData?.type,
									}}
									setFiles={() => {
										helpers.setValue(null);
										setFiles(null);
									}}
									isPreview={disabled}
									isDraggable={false}
								/>
							}
						</>
					) : (
						<>
							<FileCard
								file={files}
								fileExtraData={fileExtraData}
								setFiles={() => {
									helpers.setValue(null);
									setFiles(null);
								}}
								handleFileUploadComplete={onFileUploadComplete}
								handleFileUploadError={handleFileUploadError}
								handleFileUploadStart={handleFileUploadStart}
								isPreview={disabled}
								isDraggable={false}
							/>
						</>
					)
				) : null}
			</div>
			{meta.error && meta.touched && (
				<p className="mt-1 font-roboto text-sm !text-utility-error-500">
					{meta.error}
				</p>
			)}
		</div>
	);
};
