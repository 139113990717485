import { Flex } from "@chakra-ui/core";
import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import ButtonViewGroup from "components/ButtonViewGroup/ButtonViewGroup";
import { getRFQId } from "actions/RFQ/rfqsAction";
import { connect } from "react-redux";
import GridViewSupplier from "./GridViewSupplier";
import ListViewSupplier from "./ListViewSupplier";

import tw, { styled, css } from "twin.macro";
import InviteForm from "./InviteForm";
import { useField } from "formik";
import { Heading } from "components/Heading";

export const RFQSupplierStyled = styled.div(() => [
	tw`flex flex-col w-full`,
	css`
		.img-card {
			object-fit: cover;
			height: 104px;
			width: 100%;
		}
		.rfq-form-sub-title {
			font-family: Roboto;
			font-weight: 700;
			font-size: 20px;
			line-height: 30px;
			height: 30px;
		}
		.rfq-form-caption {
			font-family: Roboto;
			font-size: 14px;
			line-height: 20px;
			height: 20px;
			margin-bottom: 30px;
		}
		.layout-jumbotron {
			display: flex;
		}
		.layout-jumbotron-1 {
			flex: 1;
		}
		.layout-jumbotron-2 {
			flex: 1;
			align-self: flex-end;
		}
		.layout-jumbotron-title {
			font-family: Roboto;
			font-weight: 700;
			font-size: 20px;
			line-height: 30px;
			color: #101828;
		}
		.layout-jumbotron-subtitle {
			font-family: Roboto;
			font-weight: 400;
			font-size: 14px;
			line-height: 30px;
			color: #475467;
		}
	`,
]);

function Suppliers({ rfqData, showErrorMessage, setRFQData }) {
	const [view, setView] = useState("grid");
	const [localSupplierLists, setLocalSupplierLists] = useState(
		rfqData?.supplierList?.suppliers || []
	);
	const supplierListUuid = rfqData?.supplierList?.uuid;
	const [, { value: invitedSuppliers }, { setValue: setInvitedSuppliers }] =
		useField("suppliers");
	const formik = useFormikContext();

	useEffect(() => {
		if (invitedSuppliers?.length < 1)
			setInvitedSuppliers(localSupplierLists.map((supplier) => supplier.id));
		// eslint-disable-next-line
	}, [localSupplierLists]);

	const handleInviteSupplier = (supplier) => {
		setInvitedSuppliers([...invitedSuppliers, supplier]);
	};
	const handleRemoveSupplier = (supplier) => {
		setInvitedSuppliers(invitedSuppliers.filter((sup) => sup !== supplier));
	};

	const handleSupplierAttached = async () => {
		try {
			const response = await getRFQId(rfqData.id);

			if (response?.supplierList?.suppliers) {
				const newSuppliers = response.supplierList.suppliers;

				// update local state
				setLocalSupplierLists(newSuppliers);

				// Update Formik state
				const newSupplierIds = newSuppliers.map((supplier) => supplier.id);
				setInvitedSuppliers(newSupplierIds);
				formik.setFieldValue("suppliers", newSupplierIds);

				// update parent state
				if (setRFQData) {
					setRFQData(response);
				}
			}
		} catch (error) {
			console.error("Error in handleSupplierAttached:", error);
		}
	};

	const ErrorMessageComp = ({ text }) => {
		return <div className="text-md mt-2 text-red-500">{text}</div>;
	};
	return (
		<div style={{ width: "100%" }}>
			<RFQSupplierStyled>
				<div id="layout-dashboard">
					<div className="layout-jumbotron">
						<div className="layout-jumbotron-1 mt-4 py-8">
							<Heading
								fontSize={"36px"}
								color="#003CE9"
							>
								RFX PARTICIPANTS
							</Heading>
							<div
								id="subheader-page-subtitle"
								className="mt-1 font-roboto text-sm text-tertiary-600"
							>
								Confirm your Rfx participants and invite guests (non-members) to
								this Rfx below.
							</div>
							{showErrorMessage && (
								<ErrorMessageComp text="Please ensure you've added at least one supplier"></ErrorMessageComp>
							)}
						</div>
						<Flex
							gap={4}
							align={"center"}
							className="float-end"
						>
							<ButtonViewGroup
								setView={setView}
								view={view}
							/>
						</Flex>
					</div>
					<div className="relative mb-4">
						{view === "grid" ? (
							<GridViewSupplier
								supplierLists={localSupplierLists}
								invitedSuppliers={invitedSuppliers}
								handleInviteSupplier={handleInviteSupplier}
								handleRemoveSupplier={handleRemoveSupplier}
							/>
						) : (
							<ListViewSupplier
								supplierLists={localSupplierLists}
								invitedSuppliers={invitedSuppliers}
								handleInviteSupplier={handleInviteSupplier}
								handleRemoveSupplier={handleRemoveSupplier}
							/>
						)}
					</div>
					<InviteForm
						supplierListUuid={supplierListUuid}
						onSupplierAttached={handleSupplierAttached}
					/>
				</div>
			</RFQSupplierStyled>
		</div>
	);
}

const mapStateToProps = (state) => ({
	projects: state.rfq.projects.projects,
	isLoading: false,
});

const mapDispatchToProps = (dispatch) => ({
	setRFQData: (data) => dispatch({ type: "SET_RFQ_DATA", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);
