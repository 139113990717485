import { Flex } from "@chakra-ui/react";
import { QUESTION_TYPES } from "components/Pages/CreateRFQ/ReturnableSchedulesCard/QuestionTypeComponent";
import DocumentCategorySelections from "./DocumentCategorySelections";

export const SmartContent = ({ smartFieldId, formik, name }) => {
	return (
		<Flex
			direction="column"
			gap={4}
			className="my-4 text-sm font-normal"
		>
			{smartFieldId === QUESTION_TYPES.CORPORATE_DOCUMENTS && (
				<>
					<div>
						<p className="text-tertiary-600">
							This feature allows respondents to select Corporate Documents from
							their profile and provide evidence as part of their submission.
						</p>
					</div>
					<DocumentCategorySelections
						formik={formik}
						name={name}
					/>
				</>
			)}
		</Flex>
	);
};
