import { navigate } from "@reach/router";
import api from "api";
import { showToast } from "../utils/helpers";
import * as constants from "./actionTypes";
import { getCookie } from "utils/cookiesHelper";
import { apiV1 } from "api-v2";

function getApiWithToken() {
	const token = getCookie("access_token");
	return api.extend({
		hooks: {
			beforeRequest: [
				(request) => {
					request.headers.set("Authorization", `Bearer ${token}`);
				},
			],
		},
	});
}

export const searchCompanies = (values, resume, paged, supplierlist) => {
	return async (dispatch) => {
		//infinity
		if (paged) {
			dispatch({ type: constants.IS_LOADING_NEXT, payload: true });
		} else {
			dispatch({ type: constants.IS_SEARCHING, payload: paged ? false : true });
		}

		let { page, ...criteria } = values;
		(async () => {
			const results = await getApiWithToken()
				.post(`companies/smart-search?page=${paged ? paged : 1}`, {
					json: { ...criteria, items_Per_Page: 15 },
				})
				.json();
			if (supplierlist) {
				results.results.supplierlist = supplierlist;
			}
			await dispatch({
				type: constants.SEARCH_COMPANIES,
				payload: { results, values, paged },
			});
			if (!resume && results.meta && results.meta.group_id) {
				if (supplierlist?.id) {
					navigate(
						`search/${results.meta.group_id}/results?supplier=${supplierlist.id}&supplierlist=${supplierlist.name}`
					);
				} else {
					navigate(`search/${results.meta.group_id}/results`);
				}
				await dispatch({ type: constants.CLEAR_LISTS });
			}

			//infinity
			if (paged) {
				dispatch({ type: constants.IS_LOADING_NEXT, payload: false });
			} else {
				await dispatch({ type: constants.IS_SEARCHING, payload: false });
				await dispatch({ type: constants.HAS_SEARCHED, payload: true });
			}
		})();
	};
};

export const searchCompaniesDirect = (payload) => {
	return async (dispatch) => {
		dispatch({ type: constants.CLEAR_LISTS });
		dispatch({ type: constants.SEARCH_COMPANIES_DIRECT, payload: payload });
	};
};

export const addToList = (company, listType, groupId) => {
	const addingConstantVal = `ADDING_CARD_TO_${listType.toUpperCase()}`;
	return async (dispatch) => {
		dispatch({
			type: constants[addingConstantVal],
			payload: { [company.id]: true },
		});
		const data = await getApiWithToken()
			.post(`groups/${groupId}/add-company-to-list/${company.id}/${listType}`)
			.json();
		if (listType === "compare") {
			const companyItem = data.compare_list.find(
				(item) => item.company_id === company.id
			);
			dispatch({
				type: constants.ADD_COMPANY_TO_COMPARE,
				payload: {
					...companyItem.company,
					compare_projects: companyItem.projects,
				},
			});
		} else {
			const companyItem = data.shortlist_list.find(
				(item) => item.company_id === company.id
			);
			await dispatch({
				type: constants.ADD_COMPANY_TO_SHORTLIST,
				payload: {
					...companyItem.company,
					compare_projects: companyItem.projects,
				},
			});
		}
		dispatch({
			type: constants[addingConstantVal],
			payload: { [company.id]: false },
		});
	};
};

export const addALLToListCustom = (listType, data) => {
	return async (dispatch) => {
		// dispatch({ type: constants.CLEAR_LISTS });
		if (listType === "compare") {
			dispatch({
				type: constants.ADD_ALL_COMPANY_TO_COMPARE_DIRECT,
				payload: data,
			});
		} else {
			await dispatch({
				type: constants.ADD_ALL_COMPANY_TO_SHORTLIST_DIRECT,
				payload: data,
			});
		}
	};
};

export const addToListCustom = (listType, data) => {
	return async (dispatch) => {
		if (listType === "compare") {
			dispatch({
				type: constants.ADD_COMPANY_TO_COMPARE_DIRECT,
				payload: data,
			});
		} else {
			await dispatch({
				type: constants.ADD_COMPANY_TO_SHORTLIST_DIRECT,
				payload: data,
			});
		}
	};
};

export const removeCompanyToListDirect = (company) => {
	return async (dispatch) => {
		await dispatch({
			type: constants.REMOVE_COMPANY_TO_SHORTLIST_DIRECT,
			payload: company,
		});
	};
};

export const removeAllToListDirect = () => {
	return async (dispatch) => {
		await dispatch({
			type: constants.REMOVE_ALL_COMPANY_TO_SHORTLIST_DIRECT,
			payload: "",
		});
	};
};

export const removeFromList = (companyId, cardType, groupId) => {
	return async (dispatch) => {
		if (cardType === "compare") {
			await dispatch({
				type: constants.REMOVE_COMPANY_FROM_COMPARE,
				payload: companyId,
			});
			if (groupId !== "") {
				await getApiWithToken().post(
					`groups/${groupId}/remove-company-from-list/${companyId}/compare`
				);
			}
		} else if (cardType === "search") {
			await dispatch({
				type: constants.REMOVE_COMPANY_FROM_RESULTS,
				payload: companyId,
			});
			if (groupId !== "") {
				await getApiWithToken().post(
					`groups/${groupId}/exclude-company/${companyId}`
				);
			}
		} else if (cardType === "shortlist") {
			await dispatch({
				type: constants.REMOVE_COMPANY_FROM_SHORTLIST,
				payload: companyId,
			});
			if (groupId !== "") {
				await getApiWithToken().post(
					`groups/${groupId}/remove-company-from-list/${companyId}/shortlist`
				);
			}
		}
	};
};

export const reorderResults = ({ cardType, items }) => {
	if (cardType === "search") {
		return { type: constants.REORDER_SEARCH_RESULTS, payload: items };
	} else if (cardType === "compare") {
		return { type: constants.REORDER_COMPARE_RESULTS, payload: items };
	}
};

export const handleLockClick = (company, cardType, actionType) => {
	if (cardType === "search") {
		if (actionType === "add") {
			return {
				type: constants.ADD_SEARCH_RESULT_LOCKED_ITEM,
				payload: company,
			};
		} else if (actionType === "remove") {
			return {
				type: constants.REMOVE_SEARCH_RESULT_LOCKED_ITEM,
				payload: company,
			};
		}
	} else if (cardType === "compare") {
		if (actionType === "add") {
			return { type: constants.ADD_COMPARE_LOCKED_ITEM, payload: company };
		} else if (actionType === "remove") {
			return { type: constants.REMOVE_COMPARE_LOCKED_ITEM, payload: company };
		}
	}
};

export const getRegionOptions = (state = "") => {
	return async (dispatch) => {
		try {
			let regions = [];
			regions = await getApiWithToken()
				.get(`companies/postcode-regions`)
				.json();
			await dispatch({ type: constants.GET_REGION_OPTIONS, payload: regions });
		} catch (e) {
			showToast("Unable to load regions", "Error");
		}
	};
};

export const getGroup = (groupId) => {
	return async (dispatch) => {
		dispatch({ type: constants.IS_SEARCHING, payload: true });
		let group = await getApiWithToken().get(`groups/${groupId}`).json();

		await dispatch({ type: constants.GET_GROUP, payload: group.data });
		await dispatch({ type: constants.IS_SEARCHING, payload: false });
	};
};

export const getCompareList = (groupId) => {
	return async (dispatch) => {
		try {
			let compareList = await getApiWithToken()
				.get(`groups/${groupId}/compare-list`)
				.json();
			let compareListFormatted = [];

			//merge in the projects array to each company object
			compareListFormatted = compareList.data.map((item) => ({
				...item.company,
				compare_projects: item.projects,
			}));

			dispatch({
				type: constants.GET_COMPARE_LIST,
				payload: compareListFormatted,
			});
		} catch (e) {
			return e;
		}
	};
};

export const getShortlist = (groupId) => {
	return async (dispatch) => {
		let shortlist = await getApiWithToken()
			.get(`groups/${groupId}/shortlist-list`)
			.json();
		let shortlistFormatted = [];

		if (shortlist.success && shortlist.data !== null) {
			//merge in the projects array to each company object
			shortlistFormatted = shortlist.data.map((item) => ({
				...item.company,
				compare_projects: item.projects,
			}));
		}
		await dispatch({
			type: constants.GET_SHORTLIST,
			payload: shortlistFormatted,
		});
	};
};

export const getAllDisciplines = () => {
	return async (dispatch) => {
		try {
			let response = await getApiWithToken()
				.get("disciplines?page_size=9999")
				.json();
			let formattedResponse = response.data.map((discipline) => ({
				id: discipline.id,
				value: discipline.name,
			}));

			await dispatch({
				type: constants.GET_ALL_DISCIPLINES,
				payload: formattedResponse,
			});
		} catch (e) {
			showToast("Unable to load disciplines", "Error");
		}
	};
};

export const getAllTypes = () => {
	return async (dispatch) => {
		try {
			let response = await getApiWithToken().get("profile-types").json();
			await dispatch({ type: constants.GET_ALL_TYPES, payload: response.data });
		} catch (e) {
			showToast("Unable to load profile types", "Error");
		}
	};
};

export const setAddProjectIndex = (arr) => {
	return { type: constants.SET_ADD_PROJECT_INDEX, payload: arr };
};

export const addProjectToCompareCard = (
	groupId,
	companyId,
	project,
	projectSlot,
	isDirectCompare
) => {
	return async (dispatch, getState) => {
		let state = getState();
		//find the index of the company in the compare list array so we can update easily in reducer
		let companyIndex = state.search.compareList.findIndex(
			(company) => company.company_id === companyId
		);
		dispatch({ type: constants.IS_ADDING_PROJECT, payload: true });
		dispatch({
			type: constants.ADDING_PROJECT_TO_COMPARE,
			payload: { [project.id]: true },
		});

		try {
			let response = await getApiWithToken()
				.patch(
					`groups/${groupId}/compare-list/add_project_to_company/${companyId}/${project.id}/${projectSlot}`
				)
				.json();

			dispatch({
				type: constants.ADD_COMPANY_CARD_PROJECT,
				payload: {
					data: response,
					groupId,
					companyId,
					project,
					projectSlot,
					companyIndex,
					isDirectCompare,
				},
			});
			dispatch({
				type: constants.ADDING_PROJECT_TO_COMPARE,
				payload: { [project.id]: false },
			});
			dispatch({ type: constants.IS_ADDING_PROJECT, payload: false });
			return true;
		} catch (e) {
			return false;
		}
	};
};

export const removeProjectFromCompareCard = (
	groupId,
	companyId,
	project,
	index,
	isDirectCompare
) => {
	return async (dispatch, getState) => {
		const projectId = project.id;

		let state = getState();
		//find the index of the company in the compare list array so we can update easily in reducer
		let companyIndex = state.search.compareList.findIndex(
			(company) => company.id === companyId
		);

		try {
			dispatch({
				type: constants.REMOVE_COMPANY_CARD_PROJECT,
				payload: { projectId, companyId, companyIndex, index, isDirectCompare },
			});

			return await getApiWithToken()
				.patch(
					`groups/${groupId}/compare-list/remove_project_from_company/${companyId}/${projectId}/${index}`
				)
				.json();
		} catch (e) {
			return e;
		}
	};
};

export const getCalculation = () => {
	return async (dispatch) => {
		try {
			const calculation = await getApiWithToken()
				.get("subscription/calculation")
				.json();
			await dispatch({
				type: constants.GET_CALCULATION,
				payload: calculation.data,
			});
		} catch (e) {
			const error = await e.response?.json();
			return error;
		}
	};
};

export const createSupplierList = (payload) => {
	return async (dispatch) => {
		try {
			dispatch({ type: constants.CREATE_SUPPLIER_LIST, payload: payload });
		} catch (err) {}
	};
};
export const addSupplierList = (payload) => {
	return async (dispatch) => {
		try {
			dispatch({ type: constants.ADD_SUPPLIER_LIST, payload: payload });
		} catch (err) {}
	};
};
export const removeSupplierList = () => {
	return async (dispatch) => {
		try {
			dispatch({ type: constants.REMOVE_SUPPLIER_LIST });
		} catch (err) {}
	};
};

export const getSearchUserCompany = async (email) => {
	try {
		const response = await apiV1.get(
			`companies/search-user-company?email=${email}`
		);

		// Return consistent data structure
		return {
			status: true,
			data: response.data?.data || [],
		};
	} catch (err) {
		return {
			status: false,
			data: [],
		};
	}
};
