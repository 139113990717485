import { Button } from "components/RFQ/Button";
import React, { useState, useRef, useEffect } from "react";
import {
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Spinner,
} from "@chakra-ui/react";
import tw, { styled, css } from "twin.macro";
import { TabHeader } from "components/TabHeader";
import { CloseButton } from "components/RFQ/CloseButton";
import { FieldArray, Form, Formik } from "formik";
import { Checkbox } from "components/RFQ/Checkbox";
import { Icon } from "assets/icons/Icon";
import { FieldForm } from "components/RFQ/Input";
import { cn } from "utils/helpers";
import apiV2 from "api-v2";
import * as yup from "yup";
import { showToast } from "utils/helpers";
import { TextArea } from "components/FormInputs/TextArea";
import { useCompanySuggestion } from "pages/RFQ/RFQ/CreateRFQ/CreateRFQPage/Suppliers/hooks/useCompanySuggestion";
import { CompanySuggestionContent } from "pages/RFQ/RFQ/CreateRFQ/CreateRFQPage/Suppliers/components/CompanySuggestionContent";
import { useMutation } from "@tanstack/react-query";
import { postAttachSuppliersToSupplierList } from "actions/RFQ/supplierListsActions";

const DrawerBodyStyled = styled(DrawerBody)(() => [
	tw`p-6 max-h-[calc(100vh - 50px)]`,
]);
export const RFQSupplierInviteFormStyled = styled.div(() => [
	css`
		.img-card {
			object-fit: cover;
			height: 104px;
			width: 100%;
		}
		.rfq-supplier-form-wrapper {
			margin-bottom: 20px;
		}
		.rfq-form-sub-title {
			font-family: Roboto;
			font-weight: 700;
			font-size: 20px;
			line-height: 30px;
			height: 30px;
		}
		.rfq-form-caption {
			font-family: Roboto;
			font-size: 14px;
			line-height: 20px;
			height: 20px;
			margin-bottom: 30px;
		}
		.input-wrapper {
			display: flex;
			margin-top: 10px;
			margin-bottom: 10px;
		}
	`,
]);
const DrawerHeaderStyled = styled(DrawerHeader)(() => [
	tw`text-black py-6 px-0`,
	css`
		.close-text {
			font-weight: 400;
			font-size: 14px;
			cursor: pointer;
		}

		.header-container {
			display: flex;
			justify-content: space-between;
			justify-items: center;
			align-items: center;
		}

		.clear-container {
			display: flex;
			gap: 24px;
			justify-items: center;
			place-items: center;
		}
	`,
]);

const initialValues = {
	fieldData: [{ name: "", email: "", supplier_id: null }],
	additional_message: "",
};

const validationSchema = yup.object().shape({
	fieldData: yup.array().of(
		yup.object().shape({
			name: yup.string().required("Name is required"),
			email: yup.string().email("Invalid email").required("Email is required"),
		})
	),
	additional_message: yup.string().required(),
});
const InviteGuestDrawer = ({
	rfqName,
	rfqId,
	onClose,
	isOpen,
	inviteSupplierHandler,
	supplierListUuid,
	...props
}) => {
	const [currentFieldIndex, setCurrentFieldIndex] = useState(null);
	const [isChecked, setIsChecked] = useState(false);
	const [shouldFocus, setShouldFocus] = useState(null);
	const [isAttachSuccess, setIsAttachSuccess] = useState(false);
	const nameFieldRefs = useRef({});
	// eslint-disable-next-line no-unused-vars
	const [suggestionStates, setSuggestionStates] = useState({});

	// Handle focus after render
	useEffect(() => {
		if (shouldFocus !== null) {
			const input = nameFieldRefs.current[shouldFocus];
			if (input) {
				input.focus();
			}
			setShouldFocus(null);
		}
	}, [shouldFocus]);

	const {
		companyData,
		debouncedEmailCheck,
		isVisible: showSuggestion,
		handleClose: handleSuggestionClose,
		setIsVisible,
		isLoading,
	} = useCompanySuggestion({
		onValidData: () => {},
		variant: "inline",
	});

	const { mutate: inviteSupplier } = useMutation({
		mutationFn: async (payload) => {
			const res = await apiV2.post(`rfq/${rfqId}/invite-supplier`, payload);
			return res.data;
		},
		onSuccess: (data) => {
			showToast(data?.message, data?.title, true);
			inviteSupplierHandler?.(data.data?.supplierInvited);
			onClose();
		},
		onError: (error) => {
			showToast(error?.response?.data?.message, "Error");
		},
	});

	const handleSupplierAttached = (
		index,
		data,
		setFieldValue,
		setFieldTouched
	) => {
		if (!data?.company) {
			return;
		}

		// update form fields with company data
		const name = data.company.name || data.company.contact_name;
		const email = data.company.email;
		const supplier_id = data.company.id;

		setFieldValue(`fieldData.${index}.name`, name);
		setFieldValue(`fieldData.${index}.email`, email);
		setFieldValue(`fieldData.${index}.supplier_id`, supplier_id); // Store supplier_id
		setFieldTouched(`fieldData.${index}.name`, true, false);
		setFieldTouched(`fieldData.${index}.email`, true, false);
	};

	// Update the mutation to use the new handleSupplierAttached
	const { mutate: attachSupplier, isLoading: attachLoading } = useMutation({
		mutationFn: async (payload) => {
			const response = await postAttachSuppliersToSupplierList(
				supplierListUuid,
				payload.selectedIds
			);
			// if response is null or undefined, throw an error to trigger onError
			if (!response) {
				throw new Error("Failed to attach supplier");
			}

			return response;
		},
		onSuccess: (data, variables) => {
			if (data?.status === true) {
				setIsAttachSuccess(true);
				handleSupplierAttached(
					currentFieldIndex,
					{
						company: variables.selectedCompany,
						attachResponse: data,
						fromAddToList: true,
					},
					variables.formikHelpers.setFieldValue,
					variables.formikHelpers.setFieldTouched,
					variables.formikHelpers.values,
					inviteSupplier
				);
			}
		},
		onError: (error, variables) => {
			// Reset the form fields for the current index
			variables.formikHelpers.setFieldValue(
				`fieldData.${currentFieldIndex}.name`,
				""
			);
			variables.formikHelpers.setFieldValue(
				`fieldData.${currentFieldIndex}.email`,
				""
			);
			variables.formikHelpers.setFieldValue(
				`fieldData.${currentFieldIndex}.supplier_id`,
				null
			);

			// Close suggestion content
			setIsVisible(false);
			setCurrentFieldIndex(null);

			if (error?.response?.data?.message) {
				showToast(error.response.data.message, "Error");
			}
		},
	});

	const handleAddToList = (selectedIds, selectedCompany, formikHelpers) => {
		attachSupplier({
			selectedIds,
			selectedCompany,
			formikHelpers,
		});
	};

	const handleSubmit = async (values) => {
		// If suggestion is visible, prevent form submission
		if (showSuggestion && currentFieldIndex !== null && !isAttachSuccess) {
			return;
		}

		try {
			// separate suppliers and regular guests
			const supplier_ids = values.fieldData
				.filter((guest) => guest.supplier_id !== null)
				.map((guest) => guest.supplier_id);

			const regularGuests = values.fieldData
				.filter((guest) => guest.supplier_id === null)
				.map(({ name, email }) => ({ name, email }));

			const payload = {
				additional_message: values.additional_message,
			};

			if (supplier_ids.length > 0) {
				payload.supplier_ids = supplier_ids;
			}

			if (regularGuests.length > 0) {
				payload.guests = regularGuests;
			}

			let res = await apiV2.post(`rfq/${rfqId}/invite-supplier`, payload);
			if (res.status === 200) {
				showToast(res?.data?.message, res?.data?.title, true);
				inviteSupplierHandler?.(res.data.data?.supplierInvited);
				onClose();
			} else {
				showToast(res?.data?.message, res?.data?.title || "Error");
			}
		} catch (err) {
			showToast(err?.response?.data?.message, "Error");
		}
	};

	useEffect(() => {
		if (!isOpen) {
			setIsVisible(false); // Reset showSuggestion when drawer closes
			setCurrentFieldIndex(null);
			setIsAttachSuccess(false);
		}
	}, [isOpen, setIsVisible]);

	return (
		<div>
			<Drawer
				placement={"right"}
				isOpen={isOpen}
				onClose={onClose}
				size="xl"
				{...props}
			>
				<DrawerOverlay onClick={onClose} />
				<DrawerContent>
					<DrawerHeaderStyled borderBottomWidth="1px">
						<div className="mx-6">
							<TabHeader
								noDivider={true}
								heading={"Add Guest"}
								description={`Add guests to RFx "${rfqName}" from your Supplier List.`}
							>
								<CloseButton onClose={onClose} />
							</TabHeader>
						</div>
					</DrawerHeaderStyled>
					<DrawerBodyStyled>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{({
								values,
								isValid,
								dirty,
								isSubmitting,
								setFieldValue,
								setFieldTouched,
							}) => {
								const handleEmailChange = (e, index) => {
									const newValue = e.target.value;

									setFieldValue(`fieldData.${index}.email`, newValue);
									setCurrentFieldIndex(index);

									if (e.nativeEvent?.inputType === "insertFromPaste") {
										debouncedEmailCheck.cancel();
										debouncedEmailCheck(newValue);
									} else {
										debouncedEmailCheck(newValue);
									}
								};

								const handleRemoveField = (index, remove) => {
									// Remove the suggestion state for this index
									setSuggestionStates((prev) => {
										const newStates = { ...prev };
										delete newStates[index];
										// Shift remaining states up
										Object.keys(newStates).forEach((key) => {
											if (parseInt(key) > index) {
												newStates[parseInt(key) - 1] = newStates[key];
												delete newStates[key];
											}
										});
										return newStates;
									});

									if (currentFieldIndex === index) {
										setCurrentFieldIndex(null);
									} else if (currentFieldIndex > index) {
										setCurrentFieldIndex((prev) => prev - 1);
									}
									remove(index);
								};

								const handleAddField = (push) => {
									const newIndex = values.fieldData.length;
									push({ name: "", email: "", supplier_id: null });
									setShouldFocus(newIndex);
								};

								return (
									<Form>
										<FieldArray name="fieldData">
											{({ push, remove }) => (
												<div>
													{values.fieldData.map((fd, index) => (
														<RFQSupplierInviteFormStyled key={index}>
															<div className="rfq-supplier-form-wrapper rounded-md bg-utility-blue-light-100 p-6">
																<h3 className="rfq-form-sub-title">
																	Guest {index + 1}
																</h3>
																<div className="input-wrapper group relative flex items-start pr-8">
																	<div
																		style={{ flex: 1 }}
																		className="mr-3"
																	>
																		<FieldForm
																			ref={(el) => {
																				if (el) {
																					const input =
																						el.querySelector("input");
																					if (input) {
																						nameFieldRefs.current[index] =
																							input;
																					}
																				}
																			}}
																			label="Name"
																			type="text"
																			placeholder="Name"
																			name={`fieldData.${index}.name`}
																		/>
																	</div>
																	<div
																		style={{ flex: 1 }}
																		className="relative ml-3"
																	>
																		<FieldForm
																			label="Email"
																			type="text"
																			placeholder="you@example.com"
																			name={`fieldData.${index}.email`}
																			onChange={(e) =>
																				handleEmailChange(e, index)
																			}
																		/>
																		{isLoading &&
																			currentFieldIndex === index &&
																			!showSuggestion && (
																				<div className="absolute bottom-5 right-5">
																					<Spinner
																						size="sm"
																						thickness="2px"
																						speed="0.65s"
																					/>
																				</div>
																			)}
																	</div>
																	{values.fieldData.length !== 1 && (
																		<div
																			className={cn(
																				"absolute -right-4 top-10 hidden h-max w-max cursor-pointer rounded-full bg-gray-300 p-2 hover:bg-red-200 group-hover:flex"
																			)}
																			onClick={() =>
																				handleRemoveField(index, remove)
																			}
																		>
																			<Icon
																				icon="trash"
																				style={{
																					width: "20px",
																					height: "20px",
																				}}
																			/>
																		</div>
																	)}
																</div>

																{index === values.fieldData.length - 1 && (
																	<Flex
																		gap={4}
																		align={"center"}
																	>
																		<Button
																			btntype="secondary"
																			type={"button"}
																			className="!w-max"
																			onClick={() => handleAddField(push)}
																			disabled={
																				showSuggestion &&
																				!isAttachSuccess &&
																				currentFieldIndex !== null
																			}
																		>
																			<Flex
																				gap={2}
																				className="items-center"
																			>
																				<Icon icon="plus-blue" />
																				Add another
																			</Flex>
																		</Button>
																	</Flex>
																)}

																{showSuggestion &&
																	currentFieldIndex === index && (
																		<div className="mt-4">
																			{isAttachSuccess ? (
																				<div className="mr-8 flex items-center gap-6 rounded-[8px] border-2 border-royal-blue bg-white p-6">
																					<p className="font-medium text-utility-brand-700">
																						Supplier Added successfully. To
																						invite them, please proceed with the
																						invite process.
																					</p>
																					<Button
																						btntype="base"
																						size="sm"
																						onClick={() => {
																							setIsAttachSuccess(false);
																							handleSuggestionClose();
																						}}
																						type="button"
																					>
																						Close
																					</Button>
																				</div>
																			) : (
																				<CompanySuggestionContent
																					companyData={companyData}
																					variant="inline"
																					className="mr-8 rounded-[8px] border-2 border-royal-blue bg-white p-6"
																					onClose={() => {
																						setSuggestionStates((prev) => ({
																							...prev,
																							[index]: false,
																						}));
																						handleSuggestionClose();
																					}}
																					currentFieldIndex={index}
																					onAddToList={(
																						selectedIds,
																						selectedCompany
																					) =>
																						handleAddToList(
																							selectedIds,
																							selectedCompany,
																							{
																								setFieldValue,
																								setFieldTouched,
																								values,
																							}
																						)
																					}
																					isLoading={attachLoading}
																				/>
																			)}
																		</div>
																	)}
															</div>
														</RFQSupplierInviteFormStyled>
													))}
												</div>
											)}
										</FieldArray>
										<div className="">
											<TextArea
												label="Additional Message"
												name="additional_message"
												placeholder="Additional Message"
												className="flex flex-col"
												required
												errorClassName="!mt-1"
											/>
										</div>
										<div className="mb-5 mt-2 flex items-center justify-start">
											<div className="w-30">
												<Checkbox
													checked={isChecked}
													onChange={() => {
														setIsChecked(!isChecked);
													}}
												/>
											</div>
											<div
												className="ml-3 flex-1 font-roboto font-medium text-gray-700"
												style={{ fontSize: "14px" }}
											>
												I've verified the above details and all email addresses
												are correct.
											</div>
										</div>
										<Button
											type="submit"
											style={{ width: "100%" }}
											disabled={
												!isChecked ||
												!isValid ||
												!dirty ||
												isSubmitting ||
												(showSuggestion &&
													!isAttachSuccess &&
													currentFieldIndex !== null) // Only disable when showing CompanySuggestionContent
											}
										>
											{isSubmitting ? <Spinner></Spinner> : "Invite"}
										</Button>
									</Form>
								);
							}}
						</Formik>
					</DrawerBodyStyled>
				</DrawerContent>
			</Drawer>
		</div>
	);
};
export default InviteGuestDrawer;
