import React from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";

import { ResourceForm } from "../ResourceForm";
import { addResource } from "../../actions/companyActions";
import { showToast } from "utils/helpers";

export const AddResourceComponent = ({
	company,
	projectsDataStructure,
	...props
}) => {
	const initialValues = props.initialValues;

	const handleSubmit = async (values, { setErrors, setStatus }) => {
		try {
			await props.addResource(values, setErrors, setStatus);
			navigate("../resources");
			showToast("Resource added successfully.", "Success", true);
		} catch (e) {
			if (!(e instanceof Error)) {
				const emailError = e.errors?.email[0];
				setStatus(e.message);
				setErrors(emailError ? { email: emailError } : e.message);
				showToast(e.message, "Error");
			} else {
				setStatus("Request Timed out");
				setErrors("Request Timed out");
				showToast("Request Timed out", "Error");
			}
		}
	};

	return (
		<ResourceForm
			initialValues={initialValues}
			handleSubmit={handleSubmit}
			projectsDataStructure={projectsDataStructure}
		/>
	);
};

const mapStateToProps = (state) => {
	const projectsDataStructure = {
		name: "",
		role: "",
		sector_id: [],
		location: "",
		value: "",
	};

	return {
		projectsDataStructure,
		initialValues: {
			company_id: state.search.activeCompany.id || "",
			first_name: "",
			last_name: "",
			email: "",
			position: "",
			experience: "",
			roles: [1],
			location: "",
			sector_ids: [],
			projects_completed: [],
			expertise_id: "",
			discipline_ids: [],
		},
	};
};

export const AddResource = connect(mapStateToProps, { addResource })(
	AddResourceComponent
);
