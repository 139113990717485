import { useRef, useState, forwardRef, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { Button } from "components/RFQ/Button";
import { Flex } from "@chakra-ui/core";
import { Icon } from "assets/icons/Icon";
import { FieldForm } from "components/RFQ/Input";
import { cn } from "utils/helpers";
import { useCompanySuggestion } from "./hooks/useCompanySuggestion";
import { Spinner } from "@chakra-ui/core";
import React from "react";
import { CompanySuggestionContent } from "./components/CompanySuggestionContent";
import { useMutation } from "@tanstack/react-query";
import { postAttachSuppliersToSupplierList } from "actions/RFQ/supplierListsActions";
import { showToast } from "utils/helpers";

// Memo the EmailFieldForm to prevent unnecessary re-renders
const EmailFieldForm = React.memo(
	forwardRef((props, ref) => {
		const { isLoading, currentFieldIndex, fieldIndex, ...restProps } = props;

		return (
			<div
				ref={ref}
				className="relative"
			>
				<FieldForm {...restProps} />
				{isLoading && currentFieldIndex === fieldIndex && (
					<div className="absolute right-2 top-6">
						<Spinner
							size="sm"
							thickness="2px"
							speed="0.65s"
						/>
					</div>
				)}
			</div>
		);
	})
);

const InviteForm = ({ supplierListUuid, onSupplierAttached }) => {
	const emailFieldRefs = useRef({});
	const nameFieldRefs = useRef({});
	const [currentFieldIndex, setCurrentFieldIndex] = useState(null);
	const [shouldFocus, setShouldFocus] = useState(null);
	const { setFieldValue, setFieldTouched } = useFormikContext();

	const {
		companyData,
		debouncedEmailCheck,
		isVisible: showSuggestion,
		handleClose: handleSuggestionClose,
		isLoading,
		setIsVisible,
	} = useCompanySuggestion({
		onValidData: () => {},
		variant: "inline",
	});

	const fieldname = (index, name) =>
		index !== undefined ? `guest[${index}]${name}` : `guest`;
	const [, { value: guestLists }, { setValue: setGuestLists }] =
		useField(fieldname());

	// Handle focus after render
	useEffect(() => {
		if (shouldFocus !== null) {
			const input = nameFieldRefs.current[shouldFocus];
			if (input) {
				input.focus();
			}
			setShouldFocus(null);
		}
	}, [shouldFocus]);

	const handleEmailChange = (e, index) => {
		const newValue = e.target.value;

		const newGuestLists = [...guestLists];
		newGuestLists[index] = {
			...newGuestLists[index],
			email: newValue,
		};
		setGuestLists(newGuestLists);

		// only update currentFieldIndex if it's different
		if (currentFieldIndex !== index) {
			setCurrentFieldIndex(index);
		}

		debouncedEmailCheck(newValue);
	};

	const addInviteGuestFunc = () => {
		const newIndex = guestLists.length;
		setGuestLists([...guestLists, { name: "", email: "" }]);

		// Reset suggestion content if it was showing
		if (showSuggestion) {
			setIsVisible(false);
			handleSuggestionClose();
		}

		setCurrentFieldIndex(newIndex);
		setShouldFocus(newIndex);
	};

	const deleteInviteGuestFunc = (index) => {
		// Reset suggestion content if deleting the current field
		if (currentFieldIndex === index && showSuggestion) {
			setIsVisible(false);
			handleSuggestionClose();
		}

		// adjust currentFieldIndex if needed
		if (currentFieldIndex >= index) {
			setCurrentFieldIndex((prev) => (prev > 0 ? prev - 1 : null));
		}

		setGuestLists(guestLists.filter((_, i) => i !== index));
	};

	const { mutate: attachSupplier, isLoading: attachLoading } = useMutation({
		mutationFn: (payload) => {
			return postAttachSuppliersToSupplierList(
				supplierListUuid,
				payload.selectedIds
			);
		},
		onSuccess: (data, variables) => {
			if (data?.status === true) {
				handleSupplierAttached(variables.selectedCompany);
			}
		},
		onError: (error) => {
			showToast(error?.response?.data?.message, "Error");
		},
	});

	const handleAddToList = (selectedIds, selectedCompany) => {
		attachSupplier({
			selectedIds,
			selectedCompany,
		});
	};

	// Update handleSupplierAttached to accept selectedCompany
	const handleSupplierAttached = (selectedCompany) => {
		// Reset suggestion content
		setIsVisible(false);
		handleSuggestionClose();

		// if it's the first row (index 0), reset everything
		if (currentFieldIndex === 0) {
			// Reset form fields
			setGuestLists([]);
			setCurrentFieldIndex(null);

			// Reset Formik validation state
			setFieldValue("guest", []);
			setFieldTouched("guest", false);
		} else {
			// otherwise just remove the current row
			deleteInviteGuestFunc(currentFieldIndex);
		}

		// call the parent callback with selectedCompany
		onSupplierAttached?.(selectedCompany);
	};

	return (
		<div className="mb-5 grid grid-cols-2 items-end gap-10">
			<div>
				<h3 className="font-roboto text-[20px] font-bold leading-[30px]">
					Invite guests
				</h3>
				<p className="mb-4 font-roboto text-[14px] leading-[20px]">
					Invite others to respond to this request. Simply enter their email
					address below and they will receive a link to this RFx. Don't forget
					to check and verify that their email address is correct.
				</p>
				{guestLists.map((invite, index) => (
					<div
						key={index}
						className="group relative my-[10px] flex items-start gap-3 pr-4"
					>
						<div className="flex-1">
							<FieldForm
								ref={(el) => {
									if (el) {
										const input = el.querySelector("input");
										if (input) {
											nameFieldRefs.current[index] = input;
										}
									}
								}}
								type="text"
								placeholder="Name"
								icon="user3"
								name={fieldname(index, "name")}
							/>
						</div>
						<div className="relative flex-1">
							<EmailFieldForm
								ref={(el) => (emailFieldRefs.current[index] = el)}
								type="text"
								placeholder="you@example.com"
								icon="envelope"
								name={fieldname(index, ".email")}
								onChange={(e) => handleEmailChange(e, index)}
								isLoading={isLoading}
								currentFieldIndex={currentFieldIndex}
								fieldIndex={index}
							/>
						</div>
						<div
							className={cn(
								"absolute -right-6 top-4 hidden h-max w-max cursor-pointer rounded-full bg-brand-secondary p-2 group-hover:flex"
							)}
							onClick={() => deleteInviteGuestFunc(index)}
						>
							<Icon
								icon="close"
								style={{ width: "14px", height: "14px", color: "#003CE9" }}
							/>
						</div>
					</div>
				))}
				<Button
					btntype="secondary"
					type={"button"}
					className="!w-max"
					onClick={addInviteGuestFunc}
					data-testid="add-guest"
				>
					<Flex
						gap={2}
						className="items-center"
					>
						<Icon icon="plus-blue" />
						{guestLists?.length > 0 ? "Add another" : "Add guest"}
					</Flex>
				</Button>
			</div>
			{showSuggestion && currentFieldIndex !== null && (
				<div className="relative">
					<CompanySuggestionContent
						companyData={companyData}
						variant="inline"
						className="absolute bottom-0 left-0 z-[100] max-w-[600px] rounded-[8px] border-2 border-royal-blue bg-white p-6"
						onClose={handleSuggestionClose}
						currentFieldIndex={currentFieldIndex}
						onAddToList={handleAddToList}
						isLoading={attachLoading}
					/>
				</div>
			)}
		</div>
	);
};

export default InviteForm;
