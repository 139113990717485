import { apiV1 } from "api-v2";

type Discipline = {
	id: number;
	name: string;
	created_at: string;
	updated_at: string;
};

type Expertise = {
	id: number;
	name: string;
	disciplines: Discipline[];
	created_at: string;
	updated_at: string;
};

type GetListExpertiseAndDisciplinesResponse = {
	data: Expertise[];
};

export const getListExpertiseAndDisciplines = async () => {
	const response = await apiV1.get<GetListExpertiseAndDisciplinesResponse>(
		"/expertise-disciplines"
	);
	return response.data;
};
