import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getSearchUserCompany } from "actions/searchActions";
import debounce from "lodash.debounce";

const createDebouncedCheck = (callback) => debounce(callback, 400);

export const useCompanySuggestion = ({ onValidData, variant = "modal" }) => {
	const [currentEmail, setCurrentEmail] = useState("");
	const [isVisible, setIsVisible] = useState(false);
	const [forceClose, setForceClose] = useState(false);
	const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
	const [isPositionSet, setIsPositionSet] = useState(false);
	const [isCheckingEmail, setIsCheckingEmail] = useState(false);

	const { data: companyData, refetch } = useQuery({
		queryKey: ["searchUserCompany", currentEmail],
		queryFn: async () => {
			if (!currentEmail) return null;
			try {
				const result = await getSearchUserCompany(currentEmail);
				if (result?.status && Array.isArray(result?.data)) {
					if (result.data.length === 0) {
						return null;
					}
					return result;
				}
				return null;
			} catch (error) {
				console.log("Search User Company Query error:", error);
				return null;
			}
		},
		enabled: !!currentEmail,
		retry: false,
		staleTime: 0,
		cacheTime: 0,
	});

	const emailCheckCallback = useCallback(
		(email) => {
			// check for @ and . with at least one character between them
			const isValidEmailFormat =
				email && email.includes("@") && /^[^@]+@[^.]+\..+$/.test(email);

			if (isValidEmailFormat) {
				setIsCheckingEmail(true);
				(async () => {
					try {
						const response = await getSearchUserCompany(email);
						if (response?.status && Array.isArray(response?.data)) {
							const hasData = response.data.length > 0;

							setCurrentEmail(email);
							setForceClose(false);
							setIsVisible(hasData);
							onValidData?.(hasData);

							if (hasData) {
								await refetch();
							}

							if (variant === "modal") {
								setIsPositionSet(false);
							}
						}
					} catch (error) {
						console.log("API call error:", error);
						setIsVisible(false);
					} finally {
						setIsCheckingEmail(false);
					}
				})();
			} else {
				setIsVisible(false);
				setIsCheckingEmail(false);
			}
		},
		[onValidData, variant, refetch]
	);

	const debouncedEmailCheck = useMemo(
		() => createDebouncedCheck(emailCheckCallback),
		[emailCheckCallback]
	);

	const handleClose = () => {
		setForceClose(true);
		setIsVisible(false);
		setCurrentEmail("");
	};

	return {
		companyData,
		currentEmail,
		setCurrentEmail,
		debouncedEmailCheck,
		isVisible: variant === "modal" ? isVisible && isPositionSet : isVisible,
		setIsVisible,
		forceClose,
		handleClose,
		modalPosition,
		setModalPosition: (pos) => {
			setModalPosition(pos);
			setIsPositionSet(true);
			if (variant === "modal") {
				setIsVisible(true);
			}
		},
		isLoading: isCheckingEmail && !isVisible,
	};
};
