import { useCallback } from "react";

type SmartFieldType = "keyPersonnel" | "project";

interface ScrollPosition {
	documentScroll: number;
	containerScroll: number;
	elementId: string;
}

export const useSmartFieldScroll = (type: SmartFieldType) => {
	const storageKey = `${type}ScrollPosition`;

	const saveScrollPosition = (elementId: string) => {
		const elementContainer = document.getElementById("element-scroll");
		const currentCard = elementContainer
			?.querySelector(`[data-testid="${elementId}"]`)
			?.closest(".smart-field-scroll");

		if (currentCard && elementContainer) {
			const documentScroll =
				document.documentElement.scrollTop || window.pageYOffset;
			const containerScroll = elementContainer.scrollTop;

			const scrollData: ScrollPosition = {
				documentScroll,
				containerScroll,
				elementId,
			};

			// console.log(`[debug] ${type} - Saving positions:`, {
			//     ...scrollData,
			//     currentCardTop: currentCard.getBoundingClientRect().top,
			//     containerTop: elementContainer.getBoundingClientRect().top,
			// });

			sessionStorage.setItem(storageKey, JSON.stringify(scrollData));
			return true;
		}
		return false;
	};

	const restoreScrollPosition = useCallback(
		(navigateCallback: () => void) => {
			navigateCallback();

			const attemptScroll = (retries = 0) => {
				setTimeout(() => {
					const savedPositionData = sessionStorage.getItem(storageKey);
					if (savedPositionData) {
						const { documentScroll, containerScroll, elementId } =
							JSON.parse(savedPositionData);

						// console.log(`[debug] Editor - Attempting scroll restore:`, {
						//     documentScroll,
						//     containerScroll,
						//     retries,
						// });

						window.scrollTo(0, documentScroll);

						const elementContainer = document.getElementById("element-scroll");
						const targetElement = elementContainer
							?.querySelector(`[data-testid="${elementId}"]`)
							?.closest(".my-3");

						if (elementContainer && targetElement) {
							elementContainer.scrollTo({
								top: containerScroll,
								behavior: "instant",
							});

							// console.log(`[debug] Editor - Scroll completed:`, {
							//     documentScroll: document.documentElement.scrollTop,
							//     containerScroll: elementContainer.scrollTop,
							//     elementFound: !!targetElement,
							// });

							sessionStorage.removeItem(storageKey);
						} else if (retries < 3) {
							// console.log(`[debug] Editor - Elements not found, retrying...`);
							attemptScroll(retries + 1);
						}
					}
				}, 200);
			};

			attemptScroll();
		},
		[storageKey]
	);

	return {
		saveScrollPosition,
		restoreScrollPosition,
	};
};
